import React from 'react';
import cx from 'classnames';
import { SymbolWindowComponent } from '../AbstractWindow';
import { Autocomplete, Box, TextField } from '@mui/material';
import { LMDInterface } from '../../../KodiInterface/LMD';

import { HighChart } from './Highcharts';

import CircularProgress from '@mui/material/CircularProgress';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppStateManager } from '../../../StateManager';
import { PriceInfo } from '../../../Types/Websocket';

type DataPoint = { date: Date, price: number, volume: number };

type SaveState = {

};

type RunState = {
    symbols: string[];
    points: DataPoint[];
    isOpen: boolean;
}
export class Chart extends SymbolWindowComponent<RunState, SaveState> {

    private initial: boolean = true;

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            symbols: [],
            points: [],
            isOpen: false,
        }
    }

    async setNewSymbol(symbol: string) {
        this.setState({ symbol, points: [] });
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
            this.subscriptions = [];
        }
        this.initial = true;
        const { points }: { points: any } = await LMDInterface.getHistoricalGraphData(symbol);
        points.forEach(point => point.date = new Date(point.date));
        this.subscriptions.push(
            AppStateManager.MF.getHandler('price_info').subscribe(symbol,
                (priceInfo: PriceInfo | undefined) => {
                    // Prevent undefined priceInfo from updating the chart
                    if (priceInfo) {
                        // Check if there is already data for this symbol loaded in the points state
                        if (this.initial) {
                            let newPointDate = new Date();
                            newPointDate.setDate(newPointDate.getDate());
                            newPointDate.setHours(18, 0, 0, 0);
                            // Check if historical data is available for today, if so update last point
                            if (newPointDate.getTime() === points[points.length - 1].date.getTime()) {
                                points[points.length - 1].price = priceInfo?.last_price || points[points.length - 2].price;
                                points[points.length - 1].volume = priceInfo?.intraday_accumulated_volume || 0;
                                this.setState({ points });
                            } else {
                                // If no historical data is available for today, add a new point based on price info message
                                points.push({ date: newPointDate, price: priceInfo?.last_price || points[points.length - 1].price, volume: priceInfo?.intraday_accumulated_volume || 0 });
                                this.setState({ points });
                            }
                            this.initial = false;
                        } else {
                            // Update last point of current points state with new price info
                            const points = [...this.state.points];
                            if (points[points.length - 1].price !== priceInfo?.last_price || points[points.length - 1].volume !== priceInfo?.intraday_accumulated_volume) {
                                points[points.length - 1].price = priceInfo?.last_price || points[points.length - 2].price;
                                points[points.length - 1].volume = priceInfo?.intraday_accumulated_volume || 0;
                                this.setState({ points });
                            }
                        }
                    }
                }
            )
        );
    }

    componentDidMount(): void {
        LMDInterface.getAllSymbols().then(symbols => this.setState({ symbols }));
        if (this.state.symbol !== undefined) {
            this.setNewSymbol(this.state.symbol);
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot?: any): void {
        // Save state on each change
        this.saveState({ symbol: this.state.symbol, linked: this.state.linked });
    }


    render(): React.ReactNode {
        return (
            <div className='window'>
                <div className='fill' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Autocomplete
                        autoHighlight
                        popupIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '10px' }} />}
                        options={this.state.symbols}
                        sx={{
                            padding: 0,
                            '& input': {
                                maxWidth: '200px',
                                height: 10
                            },
                        }}
                        onOpen={() => this.setState({ isOpen: true })}
                        onClose={() => this.setState({ isOpen: false })}
                        renderInput={(params) => <TextField
                            {...params}
                            style={{ width: 120 }}
                            className={cx('autocomplete-custom', { 'isOpen': this.state.isOpen })}
                        />}
                        renderOption={(props, symbol) => (<Box component="li" {...props}
                            sx={{
                                color: 'var(--dark-900, #232530)',
                                fontSize: '11px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >{symbol}</Box>)}
                        disableClearable
                        value={this.state.symbol || null as any}
                        size='small'
                        onChange={(event: any, newValue: string) => this.setNewSymbol(newValue)}
                    />
                    <div>{this.linkedButton()}</div>
                </div>
                <div className='fill' style={{ overflow: 'hidden' }}>
                    <HighChart points={this.state.points} />
                    {false && this.state.points.length !== 0 && <HighChart points={this.state.points} />}
                    {false && this.state.points.length === 0 && <div className='center-container fill'><CircularProgress /></div>}
                </div>

            </div>)
    }
}