import { ErrorMessage, FieldInputProps, useField, useFormikContext } from "formik";
import Input from "../Input";
import { TranslationManager } from "../../../Translation/Translation";
import { useEffect, useState } from "react";
import { LMDInterface, TickSize } from "../../../KodiInterface/LMD";
import { getClosestTick, roundPriceToTick } from "../../../Utils/PDSUtils";
import { OrderMessage } from "../../../Types/Websocket";

export const cleanValue = (e: string | null | undefined) => {
    if (e === null || e === undefined) return '';
    const lang = TranslationManager.getActiveLanguage();
    if (lang === 'EN') {
        return e.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    } else {
        return e.replace(/[^0-9,]/g, '').replace(/(,.*)\,/g, '$1');
    }
}

export const cleanValueWithTickerFormatting = (e: number | undefined): string | undefined => {
    if (e === undefined) return undefined;
    const lang = TranslationManager.getActiveLanguage();
    if (lang === 'EN') {
        return e.toLocaleString('en-US');
    } else {
        return e.toLocaleString('is-IS');
    }
}
interface NumberInputProps extends FieldInputProps<string> {
    name: string;
    decimalScale?: number;
    allowNegative?: boolean;
    withtickerformatting?: boolean;
}
const NumberInput: React.FC<NumberInputProps> = (props) => {
    const { submitForm, setFieldValue, values } = useFormikContext<OrderMessage>();
    const [field, meta] = useField({ ...props, name: props.name });
    const [tickSizeTable, setTickSizeTable] = useState<TickSize[]>([]);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            submitForm();
        }
        if (!props.allowNegative && (event.key === '-' || event.key === '+')) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    //track when the input is in focus and the user is typing, when the user clicks outside the input, the value will be formatted

    const handleBlur = async (newTickTable?: TickSize[]) => {
        // Format the value when the input loses focus
        if (props.withtickerformatting && field.value !== undefined && field.value !== null && field.value !== '') {
            const lang = TranslationManager.getActiveLanguage();
            const PRICE = lang === 'EN' ? Number(cleanValue(field.value)) : Number(cleanValue(field.value).replace(',', '.'));
            let roundedPrice;
            const closestTick = getClosestTick(PRICE, newTickTable ?? tickSizeTable);
            if (lang === 'IS') {
                roundedPrice = roundPriceToTick(
                    PRICE,
                    closestTick
                ).toString().replace('.', ',');
            } else {
                roundedPrice = roundPriceToTick(
                    PRICE,
                    closestTick
                ).toString();
            }
            setFieldValue(props.name, roundedPrice);
        }
    };

    useEffect(() => {
        const fetchTickSizeTable = async () => {
            if (values.symbol === null || values.symbol === undefined || values.symbol === "") { setTickSizeTable([]); return; }
            const symbolInfo = await LMDInterface.getMoreTradeableInfo(values.symbol);
            if (symbolInfo !== null && symbolInfo !== undefined) {
                const { TickSizeTableId } = symbolInfo;
                const tickSizeTable = await LMDInterface.getTickSizes(TickSizeTableId);
                if (tickSizeTable) {
                    tickSizeTable.sort((a, b) =>
                        a.PriceFrom === b.PriceFrom ? 0 : a.PriceFrom < b.PriceFrom ? -1 : 1
                    );
                    setTickSizeTable(tickSizeTable);
                    handleBlur(tickSizeTable);
                }
            }
        }
        props.withtickerformatting && fetchTickSizeTable();
    }, [values.symbol]);

    return (
        <div>
            <Input
                inputType="numberFormat"
                textAlign="right"
                fullWidth
                {...props}
                type="text"
                name={props.name}
                onKeyDown={handleKeyDown}
                value={cleanValue(field.value)}
                decimalScale={props.decimalScale}
                autoComplete="off"
                onBlur={() => handleBlur()}
            />

            {meta.error && (
                <ErrorMessage className="formik-error-message" name={props.name} component="div" />
            )}
        </div>
    );
};

export default NumberInput;