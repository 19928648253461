import { MenuItem, Select } from "@mui/material";
import cx from "classnames";
import { FieldInputProps, useField } from "formik";
import React, { useEffect } from "react";
interface IProps extends FieldInputProps<string> {
    label?: string;
    labelId?: string;
    disabled?: boolean;
}
const Dropdown: React.FC<IProps & { menuItems: { value: string, title: string }[] }> = ({ menuItems, label, disabled, ...props }) => {
    const [field] = useField({ ...props, name: props.name });
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    useEffect(() => {
        props.onChange(field.value)
    }, [field.value]);
    // console.log(field, meta, helpers)
    return (
        <Select
            disabled={disabled}
            sx={{ height: '30px', width: '100%' }}
            {...field}
            labelId={props.labelId}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            style={{
                borderRadius: isOpen ? '6px 6px 0 0 ' : '6px'
            }}
            className={cx('formik-dropdown', { 'isOpen': isOpen })}
            MenuProps={{
                PaperProps: {
                    elevation: 5
                }
            }}
        >
            {
                menuItems.map((item, key) => <MenuItem key={key} value={item.value}>{item.title}</MenuItem>)
            }
        </Select >
    );
};
export default Dropdown;
