import { BehaviorSubject } from "rxjs";
import { filter, first } from "rxjs/operators";
import Bugsnag from "@bugsnag/js";
import {
  AuthenticationMessage,
  MessageType,
  StreamerError,
  SubscriptionCommands,
  SymbolMessageType,
  UnAuthorizedMessage,
  WSStatus,
} from "../../Types/Websocket";

import { PriceInfoHandler } from "./Handlers/PriceInfoHandler";
import { TradeTickHandler } from "./Handlers/TradeTickerHandler";
import { TimeHandler } from "./Handlers/TimeHandler";
import { MBOHandler } from "./Handlers/MBOHandler";
import { MBLHandler } from "./Handlers/MBLHandler";


import {
  WebSocketSubscriptionLogging,
} from "../../Config/Logging";
import { AppStateManager } from "../../StateManager";
import { WebsocketConnectionData } from "../../Types/LMDTypes";
import { hideDefaultModal, openDefaultModal } from "../../Components/Main/Main";
import { MultipleSessionsModal } from "../../Components/Modals/MultipleSessionsModal";

const WEBSOCKET_AUTHENTICATION_TIMEOUT = 10000;
class WaitableVariable<T> {
  behaviorSubject: BehaviorSubject<T | null>;
  constructor() {
    this.behaviorSubject = new BehaviorSubject(null as T | null);
  }

  // Only returns non null values
  waitForValue(): Promise<T> {
    return new Promise((resolve) =>
      this.behaviorSubject
        .pipe(
          filter((value) => value !== null),
          first()
        )
        .subscribe((value) => resolve(value as T))
    );
  }

  value(): T | null {
    return this.behaviorSubject.value;
  }

  set(value: T) {
    this.behaviorSubject.next(value);
  }

  clear() {
    this.behaviorSubject.next(null);
  }
}

export type ManageSubscriptionMessage = {
  type: MessageType;
  command: SubscriptionCommands;
  message?: any;
};
export type WSManagerHandlers = {
  market_by_level: MBLHandler;
  market_by_order: MBOHandler;
  price_info: PriceInfoHandler;
  trade: TradeTickHandler;
  time: TimeHandler;
};

/**
 * PDSManagerRecord class is responsible for managing multiple PDSSockets and their handlers.
 */
export class MFManagerRecord {
  //Current socket
  public currentSocket: MFSocket | null;
  //available sockets
  public availableSockets: WebsocketConnectionData[];
  private failedSockets: string[];

  // Handlers for different types of messages.
  private handlers: WSManagerHandlers;

  public lastHeartBeat: BehaviorSubject<Date> = new BehaviorSubject(new Date());

  // Buffer for symbol subscriptions, allowing for batched subscribe/unsubscribe operations.
  private symbolSubscriptionBuffer: Record<
    SubscriptionCommands.Subscribe | SubscriptionCommands.Unsubscribe,
    Record<SymbolMessageType, { symbols: Set<string>; timeOutSet: boolean }>
  >;

  /**
   * Returns the handler for a given type.
   * @param type - The type of the handler.
   * @returns The handler for the given type.
   */
  public getHandler<T extends keyof WSManagerHandlers>(
    type: T
  ): WSManagerHandlers[T] {
    return this.handlers[type];
  }

  /**
   * Constructs a new PDSManagerRecord.
   */
  constructor() {

    this.availableSockets = [];
    this.failedSockets = [];
    this.currentSocket = null;

    this.handlers = {
      market_by_level: new MBLHandler(),
      market_by_order: new MBOHandler(),
      price_info: new PriceInfoHandler(),
      trade: new TradeTickHandler(),
      time: new TimeHandler(),
    };

    // Information for symbol message buffering, I'd like to remove this one day
    this.symbolSubscriptionBuffer = {
      subscribe: {
        price_info: { symbols: new Set(), timeOutSet: false },
        trade: { symbols: new Set(), timeOutSet: false },
        market_by_order: { symbols: new Set(), timeOutSet: false },
        market_by_level: { symbols: new Set(), timeOutSet: false },
        // 'news': {symbols: new Set(), timeOutSet: false},
      },
      unsubscribe: {
        price_info: { symbols: new Set(), timeOutSet: false },
        trade: { symbols: new Set(), timeOutSet: false },
        market_by_order: { symbols: new Set(), timeOutSet: false },
        market_by_level: { symbols: new Set(), timeOutSet: false },
        // 'news': {symbols: new Set(), timeOutSet: false},
      },
    }
  }
  private connectToSocket(socketUrl: string, name: string, token: string) {
    // If there's an existing socket, clean it up before creating a new one
    if (this.currentSocket) {
      this.currentSocket.status.complete(); // Unsubscribe from the status
      this.currentSocket.closeSocket(); // Close the socket
      this.currentSocket = null; // Set the current socket to null
    }

    // Create a new socket
    const newSocket = new MFSocket(socketUrl, name, token, this.handlers, this.getHandler.bind(this), this.lastHeartBeat);
    this.currentSocket = newSocket;

    // Subscribe to the socket's status
    newSocket.status.subscribe((status) => {
      // If the status is 'closed', try to connect to another socket
      if (status === 'closed') {
        // Add the current socket to the failed sockets array
        this.failedSockets.push(newSocket.name as string)
        // this.toastErrorHandler('closed', newSocket.name as string);

        // If all sockets have been tried, log a message and return
        if (this.availableSockets.length === this.failedSockets.length) {
          this.failedSockets = [];
        }

        // Pick another socket
        const randomIndex = Math.floor(Math.random() * this.availableSockets.filter(x => !this.failedSockets.includes(x.name)).length);
        const randomSocket = this.availableSockets.filter(x => !this.failedSockets.includes(x.name))[randomIndex];
        // Connect to the new socket
        this.connectToSocket(randomSocket.url, randomSocket.name, token);
        return;
      }
    });
  }

  public initial(token: string) {
    // Filter all available sockets for market data
    AppStateManager.userProfile.access.forEach((connection) => {
      if (connection.type === "market_data") {
        this.availableSockets.push(connection)
      }
    })

    // If there are no available sockets, return
    if (this.availableSockets.length === 0) {
      //TODO : SET AN ERROR HERE
      //This will never happen. Since we check if the user has access to market data before connecting
      return;
    }

    // Pick a random socket and connect to it
    const randomIndex = Math.floor(Math.random() * this.availableSockets.length);
    const randomSocket = this.availableSockets[randomIndex];
    this.connectToSocket(randomSocket.url, randomSocket.name, token);
  }

  private async send(message: ManageSubscriptionMessage) {
    // Wait to send the connection untill the WSManager is connected.
    if (this.currentSocket) {
      this.currentSocket.status
        .pipe(
          filter((status) => status === "connected"),
          first()
        )
        .subscribe(async (connectedStatus) => {
          if (WebSocketSubscriptionLogging)
            console.log("Sending over websocket:");
          if (WebSocketSubscriptionLogging) console.log(message);
          //@ts-ignore
          const socket = await this.currentSocket.socket.waitForValue();
          socket.send(JSON.stringify(message));
        });
    }
  }
  // Program gets flooded with subscription message from every cell
  // This groups up symbols for 100ms before sending message
  async manageSymbol(
    symbol: string,
    command: SubscriptionCommands,
    type: SymbolMessageType
  ) {
    const bufferInfo = this.symbolSubscriptionBuffer[command][type];
    bufferInfo.symbols.add(symbol);
    if (!bufferInfo.timeOutSet) {
      bufferInfo.timeOutSet = true;
      setTimeout(() => {
        this.send({
          type,
          command,
          message: { symbols: [...bufferInfo.symbols] },
        });
        bufferInfo.symbols.clear();
        bufferInfo.timeOutSet = false;
      }, 100);
    }
  }

  async manageTimeSubscription(command: SubscriptionCommands) {
    return this.send({ type: MessageType.time, command });
  }
}

export class MFSocket {
  public socket: WaitableVariable<WebSocket>;
  public url: string;
  public name: string | undefined;
  public HB: BehaviorSubject<Date>;
  private multiple_session_detected: boolean;

  status: BehaviorSubject<WSStatus>;
  error: string | null;
  handlers: WSManagerHandlers;
  getHandler: (type: keyof WSManagerHandlers) => WSManagerHandlers[keyof WSManagerHandlers];
  private token: string | null;
  public retryTimer: {
    active: boolean,
    intervalSeconds: number,
    timer: number | null,
    networkLost: boolean,
  }
  constructor(url: string, name: string, token: string | null, handlers: WSManagerHandlers, getHandler: (type: keyof WSManagerHandlers) => WSManagerHandlers[keyof WSManagerHandlers], lastHeartBeat: BehaviorSubject<Date>) {
    this.url = url;
    this.token = token;
    this.socket = new WaitableVariable<WebSocket>();
    // this.createSocket(url, token);
    this.status = new BehaviorSubject<WSStatus>("disconnected");
    this.error = null;
    this.handlers = handlers;
    this.getHandler = getHandler;
    this.name = name;
    this.HB = lastHeartBeat;
    this.retryTimer = {
      active: true,
      intervalSeconds: 0,
      timer: null,
      networkLost: false,
    }
    this.multiple_session_detected = false;
    this.initializeSocket();
  }

  private initializeSocket() {
    //subscribe to the status of the socket
    this.status.subscribe((status) => {
      if (WebSocketSubscriptionLogging) console.log("Socket status changed:", this.name, status);
      if (status === 'disconnected') {
        if (!this.retryTimer.active || this.retryTimer.timer) return;
        if (this.retryTimer.intervalSeconds > 60) {
          this.status.next('closed');
        }
        this.retryTimer.timer = window.setTimeout(() => {
          this.retryTimer.intervalSeconds += 5;
          this.retryTimer.active = true;
          this.retryTimer.timer = null;
          //clear socket
          this.socket.clear();
          this.socket.set(this.createSocket());
        }, this.retryTimer.intervalSeconds * 1000);
      } else if (status === 'connected' || status === 'connecting') {
        this.retryTimer.timer = null;
        this.retryTimer.networkLost = false;
      }
    });
  }
  private openSocket(socket: WebSocket | null) {
    if (!socket) return;
    socket.send(
      JSON.stringify({
        type: 'authenticate',
        command: "logon",
        message: { token: this.token },
      })
    );
  }

  public createSocket(): WebSocket {
    this.multiple_session_detected = false;
    this.status.next("connecting");
    const socket = new WebSocket(this.url);

    socket.addEventListener("open", (event) => this.openSocket(socket));

    socket.addEventListener('close', () => {
      this.closeSocket();
    });
    socket.addEventListener('error', this.handleError.bind(this));
    socket.addEventListener('message', (event) => { this.handleMessage(JSON.parse(event.data)) });

    window.setTimeout(() => {
      if (this.status.value === 'connecting') {
        this.retryTimer.active = false;
        this.status.next("disconnected");
        this.error = "Websocket connection timeout";
        //TO DO DISPLAY THIS ERROR!!!
      }
    }, WEBSOCKET_AUTHENTICATION_TIMEOUT);

    return socket;
  }
  private handleMessage(messageEvent: {
    message: any;
    type: MessageType | "status" | "authenticate" | "authentication";
  }) {
    this.HB.next(new Date());
    const { message, type } = messageEvent;
    if (type in this.handlers) {
      this.getHandler(type as keyof typeof this.handlers).handleMessage(
        message
      );
    } else if (
      [
        MessageType.first_level,
        MessageType.second_level,
        MessageType.third_level as string,
      ].includes(type)
    ) {
      this.handlers[MessageType.market_by_level].handleMessage(message);
    } else if (type === "status") {
      const { status } = message as { status: any };
      this.handleStatus(status);
    } else if (type === "authenticate") {
      this.handleAuthentication(message);

    } else {
      console.error("Unhandled message from server:");
      console.log({ type });
      console.log(message);
      //TO:DO
      // SHOW ERROR MESSAGE AS TOAST FOR USER 
    }
  }

  public multipleSessions(): void {
    this.multiple_session_detected = true;
    const socket = this.socket.value()
    if (socket) {
      socket.onclose = () => { };
      socket.close();
      this.retryTimer.active = false;
      this.retryTimer.intervalSeconds = 0;
      this.retryTimer.timer = null;
      this.status.next('multiple_session');
      openDefaultModal(
        <MultipleSessionsModal reconnect={() => {
          this.createSocket();
          hideDefaultModal();
        }} />
      );
    }
  }
  public closeSocket(): void {
    const socket = this.socket.value()
    if (socket) {
      socket.onclose = () => { };
      socket.close();
      this.retryTimer.active = true;
      this.retryTimer.intervalSeconds = 0;
      this.retryTimer.timer = null;
      //to prevent the manager from trying to create another socket when the socket gets marked as closed
      //since the user will have that option to reconnect
      !this.multiple_session_detected && this.status.next('closed');
    }
  }
  private handleAuthentication(message: AuthenticationMessage | UnAuthorizedMessage) {
    if (message.authenticated) {
      this.retryTimer.intervalSeconds = 0;
      this.error = null;
      this.status.next("connected");
    } else {
      const unAuthMessage = message as UnAuthorizedMessage
      const unAuthMsg = unAuthMessage.text ?? unAuthMessage.reason
      if (unAuthMsg === StreamerError.MultipleSessions) {
        //DISPLAY ALERT FOR USER TO EITHER LOG OUT OR CLOSE OTHER SESSIONS
        //RECCONNECT WITH THE SAME TOKEN
        this.multipleSessions();
      } else if (unAuthMsg === StreamerError.TokenExpired || unAuthMsg === StreamerError.PDSTokenExpired) {
        //FORCE FETCH NEW LOGIN STATE
        // LOG THE USER OUT
        console.log('Token expired')
        AppStateManager.logout();
      } else if (unAuthMsg === StreamerError.OMSTimeout
        || unAuthMsg === StreamerError.OMSUnavailable
        || unAuthMsg === StreamerError.OMSUserNotFound
        || unAuthMsg === StreamerError.OMSOtherError) {
        Bugsnag.notify(new Error(unAuthMsg));
      } else if (unAuthMsg === StreamerError.SystemNotAllowed) {
        this.closeSocket();
      }
      this.status.next("disconnected");
    }
  }

  private handleStatus(status: "offline" | "online" | "catchup") {
    if (status === "online") {
      this.error = null;
      this.status.next("connected");
    } else if (status === "offline") {
      this.error = "Server is offline";
      // this.closeSocket();
      this.status.next("closed");
    } else if (status === "catchup") {
      this.error = "Server is in catchup";
      this.status.next("connecting");
    }
  }

  private handleError(event) {
    // IDK what to do with this information yet...
    console.error("Error on socket:");
    console.error(event);
    //this.error = JSON.stringify(event);
  }

  // public disconnect() {
  //   const socket = this.socket.value()
  //   if (socket) {
  //     socket.onclose = () => { };
  //     socket.close();
  //     this.retryTimer.active = true;
  //     this.status.next('disconnected');
  //   }
  // }
  // private handleErrorMessages(error: { reason: string, status: string }) {
  //   if (error.reason === StreamerError.MarketFeedNotOnline || error.status === "offline")
  //     this.closeSocket('handleErrorMessages');
  //   else if (error.reason === StreamerError.InvalidSymbol) { }
  //   // do nothing
  //   else if (error.reason === StreamerError.Unauthorized) { }
  //   // do nothing
  //   else if (error.reason === StreamerError.AdminClosed) { }
  //   // do nothing
  //   else {
  //     this.disconnect();
  //   }
  // }
}