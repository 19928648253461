import { TranslationManager } from "../Translation/Translation";
import { capitalizeWord } from "../Utils/Common";

// Production is set for production build that is public
// Staging is set for staging build that is used for testing
// Development is set when running locally
export type EnvironmentType = 'production'|'development'|'staging';

export const Environment: EnvironmentType = (process.env.NODE_ENV==='development'?'development':process.env.REACT_APP_BUILD_ENV) as EnvironmentType;
if(Environment !== 'production')document.title = `Keldan ${TranslationManager.getTranslation().Page.Title.toLowerCase()} ${capitalizeWord(Environment)}`;
console.log({Environment});

