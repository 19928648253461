import { Translation } from "./Translation";
import { Market } from "../svgs";

export const EnglishTranslation: Translation = {
  Page: {
    Title: "Markets",
  },

  Basic: {
    Buy: "Buy",
    Sell: "Sell",
    Fill: "Fill",
  },
  Buttons: {
    MarketData: "Market Data",
    Workspace: "Workspace",
    Trading: "Trading",
    Reset: "Reset",
    Load: "Load",
    SaveAs: "Save As",
    Settings: "Settings",
    Logout: "Logout",
    Language: "Language",
    About: "About",
    Save: "Save",
    Import: "Import",
    Export: "Export JSON",
    Ok: "Ok",
    Cancel: "Cancel",
    Confirm_order: "Confirm order",
    Reset_form: "Clear",
    Back: 'Go back',
    Reconnect: 'Reconnect',
    Hotkeys: 'Hotkeys',
  },
  Hotkeys: {
    GlobalHeader: 'Global',
    NavigateHeader: 'Navigate',
    NewOrderModal: 'Open new order modal',
    ResetWorkspace: 'Resets workspace',
    MarketDepth: 'Open market depth',
    TradeTicker: 'Open trade ticker',
    WatchList: 'Open watch list',
    Chart: 'Open chart',
    News: 'Open news',
    PriceLadder: 'Open price ladder',
    MyOrders: 'Open my orders',
    MyTrades: 'Open my trades',

    //navigational hotkeys
    Left: 'To the left',
    Right: 'To the right',
    Up: ' On top',
    Down: 'On bottom',
  },
  Errors: {
    NoSubscriptionFound: "No subscription found",
  },
  PDSErrors: {
    ReplaceChangesNothing: "No modifications were made",
  },
  ToastMessage: {
    CancelOrder: {
      pending: 'Cancelling order',
      success: 'Order cancelled',
      error: 'Failed to cancel order'
    },
    NewOrder: {
      pending: 'Placing order',
      success: 'Order placed',
      error: 'Failed to place order'
    }
  },
  Chart: {
    Volume: "Volume",
    Price: "Price",
    Start: "Start",
    End: "End",
  },
  Header: {
    Market: Market,
    Menu: {
      DashboardMenu: {
        Header: "Add to dashboard",
        MarketDepth: "MarketDepth",
        TradeTicker: "TradeTicker",
        WatchList: "WatchList",
        Chart: "Chart",
        News: "News",
      },
      WorkspaceMenu: {
        Header: "Workspace",
        ResetWorkspace: "Reset",
        OpenWorkspace: "Load",
        LoadWorkspace: "Import",
        SaveWorkspace: "Save",
        ExportWorkspace: "Export",
      },
    },
    Settings: {
      Language: "Language",
    },
  },
  WatchList: {
    RightClickTableMenu: {
      NewOrder: "New order",
      OrderbookProperties: "Orderbook properties",
      MarketDepth: "Market depth",
      Chart: "Chart",
    },
  },
  MarketDepth: { total: "total" },
  ViewOrders: {
    RightClickTableMenu: {
      Details: "Details",
      Cancel: "Cancel",
      Modify: "Modify",
    },
  },
  Titles: {
    SaveWorkspace: "Save Workspace",
    LoadWorkspace: "Load Workspace",
    ImportWorkspace: "Import Workspace",
    ExportWorkspace: "Export Workspace",
    NewOrderTitle: "New order",
    ModifyOrderTitle: "Modify order",
  },
  Login_Modal: {
    Title: "Login",
    Sub_title: "To Keldan Markets",
    Username: "Email",
    Password: "Password",
    Login: "Login",
    Remember_me: "Remember me",
    New_password: "New password",
  },
  New_Password: {
    Send_new_password: "Send new password",
    Affected_systems: "This will affect the following systems",
  },
  About_Modal: {
    Title: "About Keldan markets",
    CloseButton: "Close",
    Version: "Version",
  },
  OrderBook_Modal: {
    Orderbook_title: "Orderbook",
    Name: "Name",
    Short_Name: "Short Name",
    ISIN: "ISIN",
    Currency: "Currency",
    Country: "Country",
    Security_Type: "Security Type",
    SecurityType_share: "Share",
    SecurityType_bond: "Bond",
    ToggleButtons: {
      General: "General",
      Share_Info: "Share Info",
      Market_Info: "Market Info",
      Tick_Sizes: "Tick Sizes",
      Trading_Scheme: "Trading Scheme",
      Supplementary: "Supplementary",
    },
  },
  Trading: {
    NewOrder: "New Order",
    ViewOrders: "My Orders",
    ViewTrades: "My Trades",
    BulkOrderShift: "Bulk Order Shift",
  },
  Import_Modal: {
    DropZoneText: "Drag 'n' drop some files here, or click to select files",
    InputPlaceHolder: "Filename",
  },
  Save_Modal: {
    InputPlaceHolder: "Filename",
  },
  Settings_Modal: {
    Title: "Settings",
    CloseButton: "Close",
    Username: "Username",
    Auth_url: "Authentication URL",
    Market_data_connections: "Market data connections",
    PDS_connections: "Private data connections",
    Api_url: "Api URL",
    Kodiak_web: "Kodiak web",
    Subscriptions: "Subscriptions",
    Display_time_zone: "Display time zone",
    Delete_cache: "Delete cache",
    Active: "Active",
    Url: "Url",
    Name: "Name",
    Description: "Description",
  },
  New_Order_Modal: {
    Success_Message: "Order has been placed successfully",
    Update_Message: "Order has been changed successfully",
    Error_Message: "An error occurred",
    Dropdown_options: {
      Buy: "Buy",
      Sell: "Sell",
    },
    Labels: {
      Buy_sell: "Buy/Sell",
      Quantity: "Quantity",
      Autocomplete_symbol: "Symbol",
      Price: "Price",
      Order_type: "Order Type",
      Visible_quantity: "Visible Quantity",
      Hidden: "Hidden",
      Time_in_force: "TIF",
      Min_quantity: "Min Quantity",
      Broker: "Broker",
    },
    Total: 'Total',
    Symbol_info: {
      Last: "Last",
      Bid: "Bid",
      Ask: "Ask",
    },
  },
  News: {
    Sources: {
      Header: "Sources",
      Source_search: "Search sources",
      Toggle_all: "Toggle all",
    },
    Category_DropDown: {
      Business: "Business",
      General: "General",
      Sports: "Sports",
      Fishing_industry: "Fishing industry",
      Podcast: "Podcast",
    },
  },
  Search: "Search",
  ComponentName: {
    WatchList: "Watch List",
    Chart: "Chart",
    TradeTicker: "Trade Ticker",
    MarketDepth: "Market Depth",
    News: "News",
    PriceLadder: "Price Ladder",

    //trading components
    ViewOrders: "My Orders",
    ViewTrades: "My Trades",
    BulkOrderShift: "Bulk Order Shift",
  },
  OrderbookState: {
    closed: "Closed",
    not_available: "Not Available",
    pre_trading: "PreTrading",
    trading: "Trading",
    terminating: "Terminating",
    post_trading: "PostTrading",
    call_interaction: "CallInteraction",
    pre_close: "PreClose",
    extended_trading: "ExtendedTrading",
    mid_price_auction: "MidPriceAuction",
    opening_auction: "OpeningAuction",
    closing_auction: "ClosingAuction",
    turnover: "Turnover",
    end_of_trading: "EndOfTrading",
    statistics: "Statistics",
    orders_are_cleared: "OrdersAreCleared",
    scheduled_intraday_auction: "ScheduledIntradayAuction",
    suspended_or_halted: "SuspendedOrHalted",
    auction_period: "AuctionPeriod",
    pre_open: "PreOpen",
    trading_pause: "TradingPause",
    trading_at_closing_price: "TradingAtClosingPrice",
    sold_out_buy_back: "SoldOutBuyBack",
  },
  Columns: {
    symbol: "Symbol",
    time_executed: "Time",
    datetime_exec: "Time UTC",
    buyer: "Buyer",
    aggressive_party: "Aggr.P",
    seller: "Seller",
    price: "Price",
    yield: "Yld",
    price_dirty: "Dirty",
    duration: "Dur",
    price_per_point: "PPP",
    volume: "Volume",
    value: "Value",
    standard: "Std.",
    trade_updates_last_paid: "Upd. Last",
    trade_updates_high_low: "Upd. H/L",
    trade_updates_average: "Upd. Avg.",
    trade_updates_turnover: "Upd. Turno.",
    is_latest_trade: "Is Latest Tr.",
    outside_spread: "Outs. Spread",
    trade_cancellation: "Cancel",
    trade_cancellation_time: "Cancel Time",
    settlement_date: "Settl. Date",
    timestamp_agreement: "Time Aggr.",
    timestamp_dissemination: "Time Diss.",
    trade_id: "Trade ID",
    trade_number: "Tr. Num",
    class: "Class",
    trade_type_name: "Trade Type",
    diff_last_price: "Δ Last",

    // WatchList
    name: "Name",
    orderbook_state: "Status",
    bid_orders: "B #",
    bid_quantity: "B Vol",
    bid_price: "B Price",
    last_bid_yield_diff_closing_basispoints: "Δ B Y BP", // GUESS
    bid_price_per_point: "B PPP",
    ask_price_per_point: "A PPP",
    ask_price: "A Price",
    ask_quantity: "A Vol",
    ask_orders: "A #",
    change: "Change",
    last_price: "Last",
    last_price_diff_closing_nominal: "Δ Last ", // GUESS
    last_price_diff_closing_percent: "Δ Last %", // GUESS
    last_yield_diff_closing_nominal: "Δ Last Yield",
    intraday_no_of_trades: "# Trades",
    intraday_first_price: "Open", // GUESS
    intraday_high_price: "High", // GUESS
    intraday_low_price: "Low", // GUESS
    intraday_vwap: "VWAP",
    intraday_average_price: "Avg Px", // GUESS
    intraday_accumulated_volume: "Volume", // GUESS
    intraday_accumulated_turnover: "Turnover", // GUESS
    market_capital: "Market Cap",
    currency: "Currency",
    country: "Country",
    exchange_symbol: "Exchange",
    asset_class: "Asset Class",

    // Formated from Kodiak PRO
    bid_price_diff_percent: "Δ Last Bid %",
    bid_price_diff: "Δ Last Bid",
    ask_price_diff: "Δ Last Ask",
    ask_price_diff_percent: "Δ Last Ask %",
    bid_yield: "B Yield",
    bid_dirty: "B Dirty",
    bid_duration: "B Duration",
    ask_duration: "A Duration",
    ask_dirty: "A Dirty",
    ask_yield: "A Yield",
    last_yield: "Last Yield",
    last_yield_diff_closing_percent: "Diff Last Yield",
    last_yield_diff_closing_basispoints: "Δ Yield BP",
    last_duration: "Last Duration",
    last_dirty: "Last Dirty",
    last_price_per_point: "Last PPP",
    previous_closing_price: "PCL Price",
    previous_closing_yield: "PCL Yield",
    previous_closing_duration: "PCL Duration",
    intraday_reported_volume: "Reported Vol",
    intraday_reported_turnover: "Reported Turnover",
    notifications: "Notifications",

    // Static data columns
    Name: "Name",
    ExchangeSymbol: "Exchange Symbol",
    TradingCurrency: "Currency",
    SecurityType: " Asset Class",

    // Market Depth
    b_time: "B Time",
    b_trader: "B Trader",
    b_orders: "B #",
    b_volume: "B Vol",
    b_yield: "B Yld",
    b_dirty: "B Dirty",
    b_price: "B Price",
    a_price: "A Price",
    a_dirty: "A Dirty",
    a_yield: "A Yld",
    a_volume: "A Vol",
    a_orders: "A #",
    a_trader: "A Trader",
    a_time: "A Time",
    a_participant: "A Participant",
    b_participant: "B Participant",
    a_order_key: "A Order Key",
    b_order_key: "B Order Key",

    intraday_mid_price: "Mid",
    change_precent: "Change %",

    //****************//
    // PDS COMPONENTS //
    //****************//

    //View orders
    created_at: "Date",
    // symbol: "Symbol",
    status: "Status",
    side: "Side",
    quantity: "Quantity",
    // price: "Price",
    average_price: "Avg px",
    time_in_force: "TIF",
    visible_quantity: "Visible Qty",
    broker: "Broker",

    isin: "isin",
    // currency: "Value",
    filled_quantity: "Filled Qty",
    // last_price: "Δ Last",
    last_updated: "Last updates",
    last_updated_by: "Updated by",
    order_type: "Type",
    remaining_quantity: "Remaining",
    secondary_order_id: "Secondary order id",


    //View trades
    transactionTime: "Time",
    allocated: "Allocated",
    cancelled: "Cancelled",
    trader_username: "Trader",
    counterparty: "Counterparty",
    customer_id: "Customer id",
    customer_name: "Customer name",
    exchange_name: "Exchange",
    executed_price: "Executed price",
    executed_quantity: "Executed quantity",
    execution_id: "Execution id",
    instrument_long_name: "Instrument",
    order_class: "Order class",
    order_id: "Order id",
    submarket_name: "Submarket",
    trade_type: "Trade type",
  },
  NewOrderModal_DoubleCheck: {
    symbol: 'Symbol',
    side: 'Side',
    price: 'Price',
    quantity: 'Quantity',
    visible_quantity: 'Visible Quantity',
    order_type: 'Order Type',
    time_in_force: 'Time In Force',
    broker: 'Broker',
    // reference_id: 'Reference ID',
  }
};
