import React, { useEffect, useState } from 'react';
import { TranslationManager } from '../../Translation/Translation';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { AppStateManager } from '../../StateManager';
import { WSStatus } from '../../Types/Websocket';
import { formatTimeWithDate } from '../../Utils/Formatting';
import { Subscription } from 'rxjs';

interface MarketStatusProps {
  status: string;
}

type MarketDataConnectionType = {
  url: string | undefined;
  status: WSStatus; // replace with the actual type of the status
  type: string;
  name: string | undefined; // name can be undefined because of the optional chaining (?)
  lastHeartBeat?: Date | null;
};


export const ConnectionStatus: React.FC<MarketStatusProps> = ({ status }) => {
  const [marketDataConnection, setMarketDataConnection] = useState<MarketDataConnectionType[]>([]);
  const [PDSConnections, setPDSConnections] = useState<MarketDataConnectionType[]>([]);

  const [PDSHeartBeats, setPDSHeartBeats] = useState<Record<string, Date>>({});
  const [MFHeartBeat, setMFHeartBeat] = useState<Date | null>(null);

  const [MFStatus, setMFStatus] = useState<WSStatus>('closed');
  const [PDSStatus, setPDSStatus] = useState<Record<string, WSStatus>>({});

  useEffect(() => {
    const subscriptions: Subscription[] = [];

    // If the currentSocket is not null, subscribe to its lastHeartbeat updates
    if (AppStateManager.MF.currentSocket !== null) {
      subscriptions.push(AppStateManager.MF.lastHeartBeat.subscribe(beat => {
        setMFHeartBeat(beat)
      })
      );
      subscriptions.push(AppStateManager.MF.currentSocket.status.subscribe(status => {
        setMFStatus(status)
      }));

      // For each PDSsocket, subscribe to its lastHeartbeat updates
      Object.values(AppStateManager.PDS.PDSsockets).forEach(element => {
        subscriptions.push(element.lastHeartBeat.subscribe(
          // Update the PDSHeartBeats state with the new lastHeartbeat
          time => setPDSHeartBeats(prev => ({ ...prev, [element.url]: time })))
        );
        subscriptions.push(element.status.subscribe(
          // Update the PDSHeartBeats state with the new lastHeartbeat
          status => setPDSStatus(prev => ({ ...prev, [element.url]: status })))
        );
      });
    }

    // When the component unmounts, unsubscribe from all subscriptions
    return () => {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    };
  }, []);

  useEffect(() => {
    const MarketDataUrl = AppStateManager.MF.currentSocket?.socket.value()?.url;

    const marketDataConnection: MarketDataConnectionType[] = [{
      url: MarketDataUrl,
      status: MFStatus,
      type: 'Market Data',
      name: AppStateManager.MF.currentSocket?.name,
      lastHeartBeat: MFHeartBeat,
    }];
    const PDSUrl = AppStateManager.PDS.PDSsockets;
    const PDSConnections: MarketDataConnectionType[] = [];
    for (const key in PDSUrl) {
      PDSConnections.push({
        url: key,
        status: PDSStatus[key],
        type: 'PDS',
        name: AppStateManager.PDS.PDSsockets[key].name,
        lastHeartBeat: PDSHeartBeats[key],
      });
    }
    setMarketDataConnection(marketDataConnection);
    setPDSConnections(PDSConnections);
  }, [MFHeartBeat, PDSHeartBeats, MFStatus, PDSStatus]);
  return (
    <Tooltip arrow placement="top-start"
      title={
        <TooltipBody
          marketDataConnection={marketDataConnection}
          PDSConnections={PDSConnections}
        />
      }
    >
      <div
        className={cx('KM_marketStatus')}
        style={{ color: status }}>
        <div className='status_light' style={{ background: status }}></div>
      </div>
    </Tooltip>
  );
};
interface TooltipBodyProps {
  marketDataConnection: MarketDataConnectionType[];
  PDSConnections: MarketDataConnectionType[];

}
const TooltipBody: React.FC<TooltipBodyProps> = ({
  marketDataConnection,
  PDSConnections
}) => {
  // Define a new component for the repeated part
  const ConnectionItem = ({ item }) => (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      gap: '8px',
    }}>
      <div style={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center'
      }}>
        <div><DefaulStatusLight status={item.status} size='8' /></div>
        <div>{item.name}</div>
      </div>
      <div>{item.lastHeartBeat ? formatTimeWithDate(item.lastHeartBeat) : ''}</div>
    </div>
  );

  return (
    <div>
      {marketDataConnection.length > 0 && (
        <div>
          <span><strong>Market data</strong></span>
          {marketDataConnection.map((item, index) => (
            <ConnectionItem key={index} item={item} />
          ))}
        </div>
      )}
      {PDSConnections.length > 0 && (
        <div>
          <span><strong>Private data streamers</strong></span>
          {PDSConnections.map((item, index) => (
            <ConnectionItem key={index} item={item} />
          ))}
        </div>
      )}
    </div>
  );
}
const DefaulStatusLight: React.FC<{
  status: WSStatus,
  size?: string
}> = ({ status, size }) => {
  return <div
    style={{
      margin: '4px 0',
      width: size ? (size + 'px') : "12px",
      height: size ? (size + 'px') : "12px",
      borderRadius: "50%",
      background: status === 'connected'
        ? "var(--Market-status-trading-light)"
        : status === 'connecting'
          ? "var(--Market-status-other-light)"
          : "var(--Market-status-closed-light)",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
  </div>
}

export const MarketStatus: React.FC<MarketStatusProps> = ({ status }) => {

  const bookStateColor = {
    closed: "var(--Market-status-closed)",
    trading: "var(--Market-status-trading)",
    other: "var(--Market-status-other)",
  }[["closed", "trading"].includes(status) ? status : "other"];

  const bookStateLightColor = {
    closed: "var(--Market-status-closed-light)",
    trading: "var(--Market-status-trading-light)",
    other: "var(--Market-status-other-light)",
  }[["closed", "trading"].includes(status) ? status : "other"];

  return (
    <div className={cx('KM_marketStatus')}
      style={{ color: bookStateColor }}>
      <div className='status_light' style={{ background: bookStateLightColor }}></div>
      {TranslationManager.getTranslation().OrderbookState[status]}
    </div>
  );
};
//Settings
export const Light: React.FC<{ status: boolean, withIcon?: boolean }> = ({ status, withIcon }) => {
  return (
    <div
      style={{
        margin: '4px 0',
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        background: status
          ? "linear-gradient(180deg, #17FF2F -2.44%, #13A922 58.67%, #006A0B 104.22%)"
          : "linear-gradient(180deg, #FF1717 0%, #FA5757 0.01%, #DA0909 57.29%, #BF0101 100%)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {
        withIcon && <FontAwesomeIcon icon={status ? faCheck : faTimes} style={{ color: 'white', width: '8px', height: '8px' }} />
      }
    </div>
  );
};