import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppStateManager } from "../../StateManager";
import Button from "../UI-Elements/Button";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { TranslationManager } from "../../Translation/Translation";
import { DialogStyleContainer } from "../Header/Trading";
import Link from "../UI-Elements/Link";
import { useEffect, useRef } from "react";

interface MultipleSessionsModalProps {
    reconnect: () => void;
}
export const MultipleSessionsModal: React.FC<MultipleSessionsModalProps> = ({ reconnect }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (modalRef.current && !(modalRef.current as HTMLElement).contains(event.target as Node)) {
                AppStateManager.logout();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef]);

    const lang = TranslationManager.getActiveLanguage();
    const HEADER: JSX.Element = lang === 'EN'
        ? (
            <div className="container">
                <h1 className="header">Market feed has been disconnected
                    <FontAwesomeIcon icon={faTimes} onClick={() => AppStateManager.logout()} className="icon" />
                </h1>
                <h4>Multiple Sessions Detected:</h4>
                <p>Your account is logged in elsewhere. For security reasons, only one session per user is allowed.</p>
                <p>If this wasn't you, your account may be at risk. We recommend <Link linkSize="14" url="/new-password">changing your password</Link> immediately.</p>
                <p>If you need any assistance, please contact our support team <Link linkSize="14" url="mailto:info@keldan.is">info@keldan.is</Link></p>
                <p>Do you want to log out or reconnect?</p>
            </div>
        ) :
        (
            <div className="container">
                <h1 className="header">Markaðstenging hefur verið rofin
                    <FontAwesomeIcon icon={faTimes} onClick={() => AppStateManager.logout()} className="icon" />
                </h1>
                <h4>Margar tengingar fundust</h4>
                <p>Notandinn þinn er skráður inn á tveimur stöðum. Öryggisins vegna er einungis leyfilegt að vera með eina tengingu fyrir hvern notanda.</p>
                <p>Við mælum með því að <Link linkSize="14" url="/new-password">breyta lykilorði</Link> strax ef þú kannast ekki við þetta, þar sem reikningurinn þinn gæti verið í hættu.</p>
                <p>Vinsamlegast hafðu samband við <Link linkSize="14" url="mailto:info@keldan.is">info@keldan.is</Link> ef þú þarft aðstoð.</p>
                <p>Vilt þú skrá þig út eða endurtengjast?</p>
            </div>
        )
    return <div
        className="KM_modal KM_multipleSessionsModal"
        style={DialogStyleContainer}
        ref={modalRef}>
        <div>
            {HEADER}
        </div>

        <Button
            noMargin
            buttonType="secondary"
            size="sm"
            onClick={() => {
                AppStateManager.logout();
            }}>
            {TranslationManager.getTranslation().Buttons.Logout}
        </Button>
        <Button
            size="sm"
            onClick={() => {
                reconnect();
            }}>
            {TranslationManager.getTranslation().Buttons.Reconnect}
        </Button>
    </div >
};