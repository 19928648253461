import React from "react";
import {
  GridColDef,
  DataGridPro,
  useGridApiRef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";

import { ColumnInfo, MUITableState } from "../../Types/MUITable";
import "./MUIPro.scss";
import { CircularProgress } from "@mui/material";
import { TranslationManager } from "../../Translation/Translation";
import { GridColumnMenu } from "@mui/x-data-grid-premium";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IsoIcon from "@mui/icons-material/Iso";

import { GridColumnMenuProps, GridColumnMenuItemProps } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faCircleInfo, faPenToSquare, faTimes, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { hideDefaultModal, openDefaultModal } from "../Main/Main";
import { OrderMessage } from "../../Types/Websocket";
import { formatDetailedTime, formatNumber } from "../../Utils/Formatting";
import { calculateResizedState } from "./MUIProTable";
import { LMDInterface } from "../../KodiInterface/LMD";
import { toast } from "react-toastify";
import { ICONS } from "../../Utils/Definitions";
import NewOrderModal from "../Modals/NewOrderModal";

type StandardData = Date | null | undefined | string | number | boolean;

interface Row {
  [K: string]: StandardData;
  id: string;
}

type Input<RowType> = {
  styles?: React.CSSProperties;
  columns: ColumnInfo[];
  rows: RowType[];
  cell: (column: string, row: RowType) => JSX.Element;

  tableState: MUITableState | undefined;
  saveState: (state: MUITableState) => void;

  pagination?: boolean;
  getDetailPanelHeight?:
  | ((params: GridRowParams<any>) => number | "auto")
  | undefined;
  getDetailPanelContent?:
  | ((params: GridRowParams<any>) => React.ReactNode)
  | undefined;

  disableColumnResize?: boolean;
  disableColumnMenu?: boolean;
  slotProps?: any;
  loading?: boolean;

  // Optional
  modifyRow?: boolean;
};
export const StatuesesThatCanBeCancelled: string[] = [
  'pending_new',
  'new',
  'partially_filled',
  'pending_cancel',
  'replaced'
];
export function ViewOrderMUITable<RowType extends Row>(props: Input<RowType>) {
  const {
    styles,
    saveState,
    tableState,
    disableColumnResize,
    disableColumnMenu,
    pagination,
    getDetailPanelHeight,
    getDetailPanelContent,
    modifyRow = false,
  } = props;
  const [contextMenuPosition, setContextMenuPosition] = React.useState<{
    x: number;
    y: number;
  } | null>(null);
  const [selectedRow, setSelectedRow] = React.useState<OrderMessage | null>(
    null
  );

  const apiRef = useGridApiRef();
  // For resize
  const ref = React.createRef<any>();

  // Save state logic
  function onChange() {
    const state = apiRef.current.exportState();
    delete state["preferencePanel"];
    saveState(state);
  }
  function canModifyOrder(row: OrderMessage | null): boolean {
    //if status is rejected, filled, cancelled, expired he can't modify order
    if (row === null) return false;
    return !["rejected", "filled", "cancelled", "expired"].includes(row.status);
  }
  // Column definitions
  function MUIColumnDef(columnInfo: ColumnInfo): GridColDef {
    const { name, width, dataType, notHidable, alignment } = columnInfo;
    return {
      sortable: true,
      headerClassName: "mui-header",
      cellClassName: "mui-cell",
      hideable: notHidable === undefined ? true : !notHidable,
      field: name,
      headerAlign: alignment ? alignment : "center",
      type: dataType === undefined ? "string" : dataType,
      headerName: TranslationManager.getTranslation().Columns[name],
      width: width === undefined ? 100 : width,
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row;
        return (
          <div
            className="table-cell fill"
            {...{ "data-column-name": name }}
            style={styles}
          >
            {props.cell(name, row)}
          </div>
        );
        //return props.cell(name, row);
      },
    };
  }

  // Set default columns
  const columnVisibilityModel = {};
  props.columns.forEach(({ name, defaultHidden }) => {
    if (defaultHidden) columnVisibilityModel[name] = false;
  });
  const state =
    tableState === undefined
      ? { columns: { columnVisibilityModel } }
      : tableState;

  React.useEffect(() => {
    if (apiRef.current !== null) {
      apiRef.current.restoreState(state);
    }
  });
  return (
    <>
      <DataGridPro
        //style={{width: 'fit-content'}}
        ref={ref}
        apiRef={apiRef}
        sx={{
          border: "none",
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "var(--blue-100)",
          },
        }}
        rows={props.rows}
        columns={props.columns.map(MUIColumnDef)}
        rowHeight={25}
        columnHeaderHeight={25}
        throttleRowsMs={500}
        /* this is for the resize button to work better*/
        /* could be conditionally toggled for better performance*/
        columnBuffer={100}
        pagination={pagination}
        onSortModelChange={onChange}
        onColumnWidthChange={onChange}
        onColumnOrderChange={onChange}
        onColumnVisibilityModelChange={onChange}
        disableRowSelectionOnClick
        hideFooter={!pagination}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        disableColumnResize={
          disableColumnResize === undefined ? false : disableColumnResize
        }
        disableColumnMenu={
          disableColumnMenu === undefined ? false : disableColumnMenu
        }
        slots={{
          columnSortedAscendingIcon: function MyColumnSortedAscendingIcon() {
            return <FontAwesomeIcon icon={faCaretUp} style={{ fontSize: '10px' }} />
          },
          columnSortedDescendingIcon: function MyColumnSortedDescendingIcon() {
            return <FontAwesomeIcon icon={faCaretDown} style={{ fontSize: '10px' }} />
          },
          noRowsOverlay: function MyNoRowsOverlay() {
            return (
              <div className="fill center-container">
                {props.loading && <CircularProgress />}
              </div>
            );
          },

          columnMenu: function CustomColumnMenu(props: GridColumnMenuProps) {
            return (
              <GridColumnMenu
                {...props}
                slots={{
                  // Add new item
                  columnMenuUserItem: function CustomUserItem(
                    props: GridColumnMenuItemProps
                  ) {
                    const { myCustomHandler, myCustomValue } = props;
                    return (
                      <MenuItem onClick={myCustomHandler}>
                        <ListItemIcon>
                          <IsoIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{myCustomValue}</ListItemText>
                      </MenuItem>
                    );
                  },
                }}
                slotProps={{
                  columnMenuUserItem: {
                    displayOrder: 15,
                    myCustomValue: "Resize",
                    myCustomHandler: () => {
                      if (ref.current !== null) {
                        const tableParent = ref.current;
                        const newTableState = calculateResizedState(
                          tableParent,
                          state
                        );
                        saveState(newTableState);
                      }
                    },
                  },
                }}
              />
            );
          },
        }}
        slotProps={{
          row: {
            onContextMenu: (event: React.MouseEvent) => {
              event.preventDefault();
              setContextMenuPosition({
                x: event.clientX - 2,
                y: event.clientY - 4,
              });
              const id = event.currentTarget.getAttribute("data-id");
              const rowData = apiRef.current.getRowParams(id as string).row;
              setSelectedRow(rowData as OrderMessage);
            },
            style: { cursor: "context-menu" },
          },
        }}
      />
      <Menu
        elevation={2}
        open={contextMenuPosition !== null}
        onClose={() => setContextMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenuPosition !== null
            ? { top: contextMenuPosition.y, left: contextMenuPosition.x }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              setContextMenuPosition(null);
            },
          },
        }}
      >
        <div className="menu-symbol">
          <span>{selectedRow?.symbol}</span>
        </div>
        {/* ModifyRow option */}
        {modifyRow && <MenuItem disabled={!canModifyOrder(selectedRow)} onClick={() => {
          if (selectedRow?.symbol !== null) openDefaultModal(
            <NewOrderModal modifyOrder={selectedRow} open={true} onClose={hideDefaultModal} />
          );
          setContextMenuPosition(null);
        }}>
          <FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: 8 }} />
          {TranslationManager.getTranslation().ViewOrders.RightClickTableMenu.Modify}
        </MenuItem>}
        <MenuItem
          onClick={() => {
            if (selectedRow?.symbol !== null) openDefaultModal(<ViewDetails order={selectedRow} />);
            setContextMenuPosition(null);
          }}
        >
          <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: 8 }} />
          {
            TranslationManager.getTranslation().ViewOrders.RightClickTableMenu
              .Details
          }
        </MenuItem>
        <MenuItem
          disabled={!StatuesesThatCanBeCancelled.includes(selectedRow?.status ?? '')}
          onClick={async () => {
            if (selectedRow?.id !== null) {
              const response = await LMDInterface.deleteOrder(selectedRow?.broker, selectedRow?.id, 'User cancelled order from keldan app')
              const resolver = new Promise(resolve => {
                if (response === null) return;
                if (response.response === 'success') {
                  resolve('success')
                }
                else {
                  throw new Error('error')
                }
              }
              )
              toast.promise(resolver, {
                pending: TranslationManager.getTranslation().ToastMessage.CancelOrder.pending,
                success: TranslationManager.getTranslation().ToastMessage.CancelOrder.success,
                error: TranslationManager.getTranslation().ToastMessage.CancelOrder.error,
              }, {
                icon: response.response === "success" ? ICONS["success"] : ICONS["error"],
                autoClose: 5000
              })
            }
            setContextMenuPosition(null);
          }}
        >
          <FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />
          {
            TranslationManager.getTranslation().ViewOrders.RightClickTableMenu
              .Cancel
          }
        </MenuItem>
      </Menu>
    </>
  );
}

const ViewDetails: React.FC<{ order: OrderMessage | null }> = ({ order }) => {
  if (order === null) return <div style={{
    padding: '32px'
  }}><div>Order not found</div>
  </div>;
  const formatValue = (key: string, value: any) => {
    if (key === 'last_updated') {
      return formatDetailedTime(value);
    }
    if (key === 'created_at') {
      return formatDetailedTime(value);
    }
    if (key === 'time_in_force') {
      return value.replaceAll('_', ' ');
    }
    if (key === 'side') {
      const lang = TranslationManager.getActiveLanguage();
      if (lang === 'IS') return (value === 'buy' ? 'Kaupa' : 'Selja')
      return value === 'buy' ? 'Buy' : 'Sell';
    }
    if (typeof value === 'number') {
      return formatNumber(value);
    }
    return value;
  }
  return (

    <div style={{
      padding: '32px'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px'
      }}>
        <h1 style={{ lineHeight: '27px' }}>{order.symbol}</h1>
        <FontAwesomeIcon icon={faTimes} onClick={() => hideDefaultModal()} style={{ cursor: 'pointer' }} />
      </div>
      <div style={
        {
          columnCount: 2,
          columnGap: '16px',
          overflow: 'auto',
        }
      }>
        {Object.entries(order).map(([key, value]) => {
          if (key === 'id') return null;
          return (
            <div style={{
              width: '100%',
              breakInside: 'avoid',
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid var(--dark-050)',
              padding: '4px 0',
              columnGap: '50px'
            }} key={key}>
              <span style={{ fontWeight: '500' }}>{TranslationManager.getTranslation().Columns[key]}</span>
              <span style={{
                textTransform: 'capitalize'
              }}>{formatValue(key, value)}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}