import { Subscription } from "rxjs";
import { ListInfo } from "../../../Types/LMDTypes";
import { SymbolWindowComponent } from "../AbstractWindow";
import { OrderMessage, TradeMessage } from "../../../Types/Websocket";
import { ViewOrderMUITable } from "../../Tables/ViewOrderMUIProTable";
import { formatNumber, formatTimeWithDate } from "../../../Utils/Formatting";
import { MUITableState } from "../../../Types/MUITable";
import { getPDSHandler } from "../../../StateManager";
import TableBar from "../../UI-Elements/TableBar";
import { SymbolSelector } from "../MarketDepth/Components";
import { TradeColumnsInfo } from "./Columns";
import Dropdown from "../../UI-Elements/DropDownWrapper";

type SaveState = {
  linked: boolean;
  symbolOrList: ListInfo | string | undefined;
  tableState: MUITableState | undefined;
};

type RunState = {
  trades: TradeMessage[];
  symbols: string[];
  lists: ListInfo[];
  loading: boolean;
  isOpen: boolean;
  trade_status: string;
  trader: string;
};
export class ViewTrades extends SymbolWindowComponent<RunState, SaveState> {
  linkSubscriptions: Subscription[];
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      trades: [],
      symbols: [],
      lists: [],
      loading: false,
      trade_status: "all",
      trader: "any",
      isOpen: false,
    };
    this.linkSubscriptions = [];
  }

  componentDidMount(): void {
    if (this.state.symbol !== undefined) this.setNewSymbol(this.state.symbol);

    getPDSHandler("view_trades").subscribe((trades) => {
      this.setState({ trades: trades });
    });
    this.setState({ loading: false });
  }

  async setNewSymbol(symbol: string) {
    this.setState({ symbol });
  }

  componentDidUpdate(prevProps, prevState, snapshot?: any): void {
    // Save state on each change
    this.saveState({
      symbol: this.state.symbol,
      linked: this.state.linked,
      symbolOrList: this.state.symbolOrList,
      tableState: this.state.tableState,
    });
  }
  filterTableData(): TradeMessage[] {
    if (this.state.symbol !== null && this.state.symbol !== undefined && this.state.symbol !== "") {
      return this.state.trades.filter(
        (trades) => trades.symbol === this.state.symbol
      );
    }
    return this.state.trades;
  }

  filterOrdersByTrader(orders: TradeMessage[], trader: string): TradeMessage[] {
    if (trader !== 'any') {
      return orders.filter(order => order.trader_username === trader);
    }
    return orders;
  }

  render() {
    let ordersToShow = this.filterTableData();


    ordersToShow = this.filterOrdersByTrader(ordersToShow, this.state.trader);
    const menuItems = [{ value: 'any', title: 'All traders' }]
    let availableTraders = this.state.trades.map(order => order.trader_username).filter((value, index, self) => self.indexOf(value) === index).map(trader => {
      return {
        value: trader,
        title: trader
      }
    });
    menuItems.push(...availableTraders);
    // console.log('trades', ordersToShow)
    return (
      <div className="window">
        {/* dropdown select */}
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "5px",
          }}
        >
          <SymbolSelector
            width={120}
            selectedSymbol={this.state.symbol}
            onSelect={(symbol) => this.setNewSymbol(symbol)}
            disableClearable={false}
          />
          <div style={{ width: '140px' }}>

            <Dropdown height="20px" menuItems={menuItems} />
          </div>
          {this.linkedButton()}
        </div>
        <ViewOrderMUITable
          rows={ordersToShow.sort((a, b) => {
            return new Date(b.transactionTime).getTime() - new Date(a.transactionTime).getTime();
          }).map((order, index) => {
            return { ...order, id: order.symbol + index };
          })}
          columns={TradeColumnsInfo}
          cell={(column, row) =>
            formatCell(column, row as unknown as OrderMessage)
          }
          tableState={this.state.tableState}
          saveState={(state) => this.setState({ tableState: state })}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

function formatCell(column: string, row: OrderMessage): JSX.Element {
  var value = row[column];
  const style = {};
  if (typeof value === 'string') {
    style['justifyContent'] = 'flex-start';
  }
  if (column === "transactionTime") value = formatTimeWithDate(value as string);
  else if (column === "side") return <TableBar value={value} />;
  else if (column === "order_id") {
    style['justifyContent'] = 'flex-start';
    return <div className="center-container fill" style={style}>{value}</div>;
  }
  else if (typeof value === 'number') {
    style['justifyContent'] = 'flex-end';
    value = formatNumber(value);
  }
  return (
    <div className="center-container fill" style={style}>
      {value}
    </div>
  );
}
