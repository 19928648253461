import { BehaviorSubject, Subscription } from "rxjs";
import { MessageType, SubscriptionCommands } from "../../../Types/Websocket";
import { MessageHandler } from "./AbstractHandler";
import { AppStateManager } from "../../../StateManager";
import { ManageSubscriptionMessage } from "../MarketFeedWebsocketManager";

function stringTimeToDate(timeString: string) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    const date = new Date();
    date.setHours(hours); date.setMinutes(minutes); date.setSeconds(seconds);
    return date;
}

type TimeMessage = { time: string };//{time: '13:27:07';

// Only allows for one subscriber at a time
export class TimeHandler extends MessageHandler<TimeMessage> {
    private behaviorSubject: BehaviorSubject<Date>;

    constructor() {
        super();
        this.behaviorSubject = new BehaviorSubject(new Date());
    }

    subscribe(callback: (time: Date) => void): Subscription {
        //TODO if TimeHandler should support more then one subscription 
        //have subscribe counter or whatever for many clocks
        AppStateManager.MF.manageTimeSubscription(SubscriptionCommands.Subscribe);
        const subscription = this.behaviorSubject.subscribe(callback);
        subscription.add(() => AppStateManager.MF.manageTimeSubscription(SubscriptionCommands.Unsubscribe));
        return subscription;
    }

    handleMessage(message: TimeMessage) {
        const date = stringTimeToDate(message.time);
        this.behaviorSubject.next(date)
    }

    regenerateSubscriptionMessage(): ManageSubscriptionMessage {
        return { type: MessageType.time, command: SubscriptionCommands.Subscribe };
    }
}