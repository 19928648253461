import { Component } from "react";
import { Subject, Subscription } from "rxjs";
import { ListInfo } from "../../Types/LMDTypes";

import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";

/*
<Button size='small' variant="text" color="secondary" onClick={() => {this.state.linked?this.unlink():this.link()}}>
    {!this.state.linked && <LinkOffIcon style={{fill: 'var(--background-color-light)'}}/>}
    {this.state.linked && <LinkIcon style={{fill: '#fff'}}/>}
</Button>
*/

type Input<SaveState> = {
  state: SaveState;
  GLSaveState: (state: SaveState) => void;
};
export abstract class WindowComponent<
  RunState,
  SaveState extends { linked: boolean }
> extends Component<Input<SaveState>, RunState & SaveState> {
  subscriptions: Subscription[];
  linkedButton: () => JSX.Element;
  constructor(props: Input<SaveState>) {
    super(props);
    this.state = props.state as RunState & SaveState;
    this.subscriptions = [];
    this.linkedButton = () => (
      <div
        style={{ position: "absolute", right: 5, top: 0, cursor: "pointer" }}
      >
        {!this.state.linked && (
          <LinkOffIcon
            style={{ fill: "var(--window-not-linked)" }}
            onClick={this.link.bind(this)}
          />
        )}
        {this.state.linked && (
          <LinkIcon
            style={{ fill: "var(--window-linked)" }}
            onClick={this.unlink.bind(this)}
          />
        )}
      </div>
    );
  }

  abstract link(): void;
  abstract unlink(): void;

  saveState(state: SaveState) {
    this.props.GLSaveState(state);
  }

  componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

export abstract class NewsWindowComponent<
  RunState,
  SaveState
> extends WindowComponent<
  RunState,
  SaveState & { symbol: string | undefined; linked: boolean }
> {
  linkSubscriptions: Subscription[];
  constructor(props) {
    super(props);
    this.linkSubscriptions = [];
    if (this.props.state.linked) this.subscribeGLWM();
  }

  abstract setNewSymbol(symbol: Symbol): void;

  private subscribeGLWM() {
    const GLWM = getGlobalLinkedWindowManager();
    const subscription = GLWM.listenerSubscribe((symbol) =>
      this.setNewSymbol(symbol)
    );
    this.linkSubscriptions.push(subscription);
  }
  link() {
    this.subscribeGLWM();
    this.setState({ linked: true as any });
  }

  unlink() {
    this.linkSubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.linkSubscriptions = [];
    this.setState({ linked: false as any });
  }
}

export abstract class SymbolWindowComponent<
  RunState,
  SaveState
> extends WindowComponent<
  RunState,
  SaveState & { symbol: string | undefined; linked: boolean }
> {
  // There should normally only be one link subscription
  // but lists are better at dealing with edge cases
  linkSubscriptions: Subscription[];
  constructor(props) {
    super(props);
    this.linkSubscriptions = [];
    if (this.props.state.linked) this.subscribeGLWM();
  }

  private subscribeGLWM() {
    const GLWM = getGlobalLinkedWindowManager();
    const subscription = GLWM.listenerSubscribe((symbol) =>
      this.setNewSymbol(symbol)
    );
    this.linkSubscriptions.push(subscription);
  }

  abstract setNewSymbol(symbol: Symbol): void;

  link() {
    this.subscribeGLWM();
    this.setState({ linked: true as any });
  }

  unlink() {
    this.linkSubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.linkSubscriptions = [];
    this.setState({ linked: false as any });
  }

  componentWillUnmount(): void {
    this.linkSubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

export abstract class SymbolListWindowComponent<
  RunState,
  SaveState
> extends WindowComponent<
  RunState,
  SaveState & { list: ListInfo | undefined; linked: boolean }
> {
  constructor(props) {
    super(props);
  }

  selectedSymbol(symbol: Symbol): void {
    if (this.state.linked) {
      const GLWM = getGlobalLinkedWindowManager();
      GLWM.select(symbol);
    }
  }

  link() {
    this.setState({ linked: true as any });
  }
  unlink() {
    this.setState({ linked: false as any });
  }
}

// Might but into its own file later
type Symbol = string;
class LinkedWindowManager {
  private subject: Subject<Symbol>;
  constructor() {
    this.subject = new Subject<Symbol>();
  }

  listenerSubscribe(callback: (symbol: Symbol) => void): Subscription {
    return this.subject.subscribe(callback);
  }

  select(symbol: Symbol) {
    this.subject.next(symbol);
  }
}
const GlobalLinkedWindowManager = new LinkedWindowManager();
export function getGlobalLinkedWindowManager(): LinkedWindowManager {
  return GlobalLinkedWindowManager;
}
