import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { TranslationManager } from '../../Translation/Translation';

const Button = styled.button`
    position: relative;
    background: white;
    overflow: hidden;
    height: fit-content;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    padding: 6px 14px;
    border: 1px solid var(--blue-600);
    margin: 4px;
    margin-left: 0px;
`;

const AnimatedBackground = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--blue-200);
`;

const ClearAllButton = () => {
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [animationComplete, setAnimationComplete] = useState(false);
    const { resetForm, handleReset } = useFormikContext(); // get resetForm method from Formik context

    useEffect(() => {
        let timeoutId;
        if (isMouseDown) {
            timeoutId = setTimeout(() => {
                setAnimationComplete(true);
                handleReset(); // reset the form
            }, 1000);
        } else {
            clearTimeout(timeoutId);
            setAnimationComplete(false);
        }
        return () => clearTimeout(timeoutId);
    }, [isMouseDown]);

    return (
        <Button
            type="button"
            onMouseDown={() => setIsMouseDown(true)}
            onMouseUp={() => setIsMouseDown(false)}
        >
            <span style={{ position: 'relative', zIndex: '1', color: 'var(--blue-600)' }}>{TranslationManager.getTranslation().Buttons.Reset_form}</span>
            <AnimatePresence>
                {isMouseDown && !animationComplete && (
                    <AnimatedBackground
                        initial={{ width: 0 }}
                        animate={{ width: '100%' }}
                        exit={{ width: 0, transition: { duration: 0.25 } }} // exit animation will take 0.25 seconds
                        transition={{ duration: 1 }}
                    />
                )}
            </AnimatePresence>
        </Button>
    );
};

export default ClearAllButton;