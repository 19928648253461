import { Subscription } from "rxjs";
import { ListInfo } from "../../../Types/LMDTypes";
import { SymbolWindowComponent } from "../AbstractWindow";
import { OrderMessage } from "../../../Types/Websocket";
import { MenuItem, Select } from "@mui/material";
import { ViewOrderMUITable } from "../../Tables/ViewOrderMUIProTable";
import { OrderColumnsInfo } from "./Columns";
import { formatNumber, formatTimeWithDate } from "../../../Utils/Formatting";
import { MUITableState } from "../../../Types/MUITable";
import { getPDSHandler } from "../../../StateManager";
import TableBar from "../../UI-Elements/TableBar";
import { SymbolSelector } from "../MarketDepth/Components";

type SaveState = {
  linked: boolean;
  symbolOrList: ListInfo | string | undefined;
  tableState: MUITableState | undefined;
};

type RunState = {
  orders: OrderMessage[];
  symbols: string[];
  lists: ListInfo[];
  loading: boolean;
  isOpen: boolean;
  trade_status: string;
  trader: string;
};
export class ViewOrders extends SymbolWindowComponent<RunState, SaveState> {
  linkSubscriptions: Subscription[];
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      orders: [],
      symbols: [],
      lists: [],
      loading: false,
      trade_status: "all",
      trader: "any",
      isOpen: false,
    };
    this.linkSubscriptions = [];
  }

  componentDidMount(): void {
    if (this.state.symbol !== undefined) this.setNewSymbol(this.state.symbol);

    getPDSHandler("view_orders").subscribe((orders) => {
      this.setState({ orders: orders });
    });
    this.setState({ loading: false });
  }

  async setNewSymbol(symbol: string) {
    this.setState({ symbol });
  }

  componentDidUpdate(prevProps, prevState, snapshot?: any): void {
    // Save state on each change
    this.saveState({
      symbol: this.state.symbol,
      linked: this.state.linked,
      symbolOrList: this.state.symbolOrList,
      tableState: this.state.tableState,
    });
  }
  filterTableData(): OrderMessage[] {
    if (this.state.symbol !== null && this.state.symbol !== undefined && this.state.symbol !== "") {
      return this.state.orders.filter(
        (order) => order.symbol === this.state.symbol
      );
    }
    return this.state.orders;
  }


  filterOrdersByTradeStatus(orders: OrderMessage[], tradeStatus: string): OrderMessage[] {
    //if trade_status is open show only order with status "new"
    //if trade_status is closed show only order with status "filled", "rejected", "cancelled"
    //if trade_status is executed show only order with status "filled"
    switch (tradeStatus) {
      case 'open':
        return orders.filter(order => order.status === 'new');
      case 'closed':
        return orders.filter(order => ['filled', 'rejected', 'cancelled'].includes(order.status));
      case 'executed':
        return orders.filter(order => order.status === 'filled');
      default:
        return orders;
    }
  }

  filterOrdersByTrader(orders: OrderMessage[], trader: string): OrderMessage[] {
    if (trader !== 'any') {
      return orders.filter(order => order.last_updated_by === trader);
    }
    return orders;
  }

  render() {
    let ordersToShow = this.filterTableData();
    ordersToShow = this.filterOrdersByTradeStatus(ordersToShow, this.state.trade_status);
    ordersToShow = this.filterOrdersByTrader(ordersToShow, this.state.trader);
    let availableTraders = this.state.orders.map(order => order.last_updated_by).filter((value, index, self) => self.indexOf(value) === index);
    return (
      <div className="window">
        {/* dropdown select */}
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "5px",
          }}
        >
          <SymbolSelector
            width={120}
            selectedSymbol={this.state.symbol}
            onSelect={(symbol) => this.setNewSymbol(symbol)}
            disableClearable={false}
          />
          <Select
            style={{ height: '20px' }}
            className="sm"
            value={this.state.trade_status}
            onChange={(event) =>
              this.setState({ trade_status: event.target.value })
            }
          >
            <MenuItem value="all">All status</MenuItem>
            <MenuItem value="open">Open</MenuItem>
            <MenuItem value="closed">Closed</MenuItem>
            <MenuItem value="executed">Executed</MenuItem>
          </Select>
          <Select
            style={{ height: '20px' }}
            className="sm"
            value={this.state.trader}
            onChange={(event) => this.setState({ trader: event.target.value })}
          >
            <MenuItem value="any">All traders</MenuItem>x
            {availableTraders.map((trader, index) => (
              <MenuItem key={index} value={trader}>{trader}</MenuItem>
            ))
            }
          </Select>
          {this.linkedButton()}
        </div>
        <ViewOrderMUITable
          modifyRow={true}
          rows={ordersToShow.sort((a, b) => {
            return new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime();
          }).map((order, index) => {
            return { ...order };
          })}
          columns={OrderColumnsInfo}
          cell={(column, row) =>
            formatCell(column, row as unknown as OrderMessage)
          }
          tableState={this.state.tableState}
          saveState={(state) => this.setState({ tableState: state })}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

function formatCell(column: string, row: OrderMessage): JSX.Element {
  var value = row[column];
  const style = {};
  if (typeof value === 'string') {
    style['justifyContent'] = 'flex-start';
  }
  if (column === "created_at") value = formatTimeWithDate(value as string);
  else if (column === "last_updated") value = formatTimeWithDate(value as string);
  else if (column === "side") return <TableBar value={value} />;
  else if (column === "time_in_force" && value === "good_till_cancel") {
    style['justifyContent'] = 'flex-start';
    value = "GTC";
  }
  else if (typeof value === 'number') {
    style['justifyContent'] = 'flex-end';
    value = formatNumber(value);
  }
  return (
    <div className="center-container fill" style={style}>
      {value}
    </div>
  );
}
