import React, { useEffect, useState } from "react";
import {
  GridColDef,
  DataGridPro,
  useGridApiRef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";

import { ColumnInfo, MUITableState } from "../../Types/MUITable";
import "./MUIPro.scss";
import { CircularProgress } from "@mui/material";
import { TranslationManager } from "../../Translation/Translation";
import { GridColumnMenu } from "@mui/x-data-grid-premium";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import IsoIcon from "@mui/icons-material/Iso";

import { GridColumnMenuProps, GridColumnMenuItemProps } from "@mui/x-data-grid";
import Input from "../UI-Elements/Input";

type StandardData = Date | null | undefined | string | number | boolean;

interface Row {
  [K: string]: StandardData;
  id: string;
}

type Input<RowType> = {
  styles?: React.CSSProperties;
  columns: ColumnInfo[];
  rows: RowType[];
  cell: (column: string, row: RowType) => JSX.Element;

  tableState: MUITableState | undefined;
  saveState: (state: MUITableState) => void;

  pagination?: boolean;
  getDetailPanelHeight?:
  | ((params: GridRowParams<any>) => number | "auto")
  | undefined;
  getDetailPanelContent?:
  | ((params: GridRowParams<any>) => React.ReactNode)
  | undefined;

  disableColumnMenu?: boolean;
  slotProps?: any;
  loading?: boolean;
  newLineFlicker?: boolean;
};

export function MUITable<RowType extends Row>(props: Input<RowType>) {
  const {
    styles,
    saveState,
    tableState,
    disableColumnMenu,
    pagination,
    getDetailPanelHeight,
    getDetailPanelContent,
    newLineFlicker,
  } = props;

  const apiRef = useGridApiRef();
  // For resize
  const ref = React.createRef<any>();

  // Save state logic
  function onChange() {
    const state = apiRef.current.exportState();
    delete state["preferencePanel"];
    saveState(state);
  }
  // Column definitions
  function MUIColumnDef(columnInfo: ColumnInfo): GridColDef {
    const { name, width, dataType, notHidable, alignment } = columnInfo;
    return {
      sortable: false,
      headerClassName: "mui-header",
      cellClassName: "mui-cell",
      hideable: notHidable === undefined ? true : !notHidable,
      field: name,
      headerAlign: alignment ? alignment : "center",
      type: dataType === undefined ? "string" : dataType,
      headerName: TranslationManager.getTranslation().Columns[name],
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row;
        return (
          <div
            className="table-cell fill"
            {...{ "data-column-name": name }}
            style={styles}
          >
            {props.cell(name, row)}
          </div>
        );
        //return props.cell(name, row);
      },
    };
  }

  // Set default columns
  const columnVisibilityModel = {};
  props.columns.forEach(({ name, defaultHidden }) => {
    if (defaultHidden) columnVisibilityModel[name] = false;
  });
  const state =
    tableState === undefined
      ? { columns: { columnVisibilityModel } }
      : tableState;

  React.useEffect(() => {
    if (apiRef.current !== null) {
      apiRef.current.restoreState(state);
    }
  }, [apiRef]);
  // Assuming rows have a unique 'id' property
  const [whatIsVisible, setWhatIsVisible] = useState<string[]>([]);
  const [whatToHighlight, setWhatToHighlight] = useState<string[]>([]);

  useEffect(() => {
    const allRowsId = props.rows.map((row) => row.trade_id as string);
    const whatToShow = allRowsId.filter((id) => !whatIsVisible.includes(id));
    setWhatIsVisible(prev => [...prev, ...whatToShow]);
    setWhatToHighlight(whatToShow);
  }, [props.rows]);

  // Modify getRowClassName to apply 'newLineFlicker' based on newRowIds state
  const getRowClassName = (params) => {
    return newLineFlicker && whatToHighlight.includes(params.row.trade_id) ? 'newLineFlicker' : '';
  };

  return (
    <>
      <DataGridPro
        ref={ref}
        apiRef={apiRef}
        // className={newLineFlicker ? "newLineFlicker" : ""}
        sx={{
          border: "none",
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "var(--blue-100)",
          },
        }}
        rows={props.rows}
        columns={props.columns.map(MUIColumnDef)}
        rowHeight={25}
        columnHeaderHeight={25}
        throttleRowsMs={500}
        /* this is for the resize button to work better*/
        /* could be conditionally toggled for better performance*/
        columnBuffer={100}
        pagination={pagination}
        onSortModelChange={onChange}
        onColumnWidthChange={onChange}
        onColumnOrderChange={onChange}
        onColumnVisibilityModelChange={onChange}
        disableRowSelectionOnClick
        disableColumnReorder={false}
        hideFooter={!pagination}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        //Disabling the virtualization will increase the size of the DOM and drastically reduce the performance. Use it only for testing purposes or on small datasets.
        //The only reason we use it here is for the row flicker effect
        //by having virtualization enabled, the datagrid only renders x amount of rows. Instead of rendering all rows at once and keeping them in the DOM.
        //But for many of our tables, they are limited to 200 rows. So the performance hit is not that big.
        disableVirtualization={newLineFlicker}

        //check if the time is less than second from this moment, add a new class to the row
        getRowClassName={getRowClassName}
        disableColumnMenu={
          disableColumnMenu === undefined ? false : disableColumnMenu
        }
        slots={{
          loadingOverlay: function MyLoadingOverlay() {
            return <CircularProgress />;
          },
          noRowsOverlay: function MyNoRowsOverlay() {
            return (
              <div className="fill center-container">
                {props.loading && <CircularProgress />}
              </div>
            );
          },
          columnMenu: function CustomColumnMenu(props: GridColumnMenuProps) {
            return (
              <GridColumnMenu
                {...props}
                slots={{
                  // Add new item
                  columnMenuUserItem: function CustomUserItem(
                    props: GridColumnMenuItemProps
                  ) {
                    const { myCustomHandler, myCustomValue } = props;
                    return (
                      <MenuItem onClick={myCustomHandler}>
                        <ListItemIcon>
                          <IsoIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{myCustomValue}</ListItemText>
                      </MenuItem>
                    );
                  },
                }}
                slotProps={{
                  columnMenuUserItem: {
                    displayOrder: 15,
                    myCustomValue: "Resize",
                    myCustomHandler: () => {
                      if (ref.current !== null) {
                        const tableParent = ref.current;
                        const newTableState = calculateResizedState(
                          tableParent,
                          state
                        );
                        saveState(newTableState);
                      }
                    },
                  },
                }}
              />
            );
          },
        }}
      />
    </>
  );
}

// ChatGPT solution
export function estimateWidth(text: string, fontSize: number): number {
  // Create a temporary span element
  const span = document.createElement("span");

  // Set its content and styles
  span.textContent = text;
  span.style.fontSize = fontSize + "px";
  span.style.position = "absolute"; // so it doesn't affect layout
  span.style.left = "-10000px"; // move it off-screen
  span.style.whiteSpace = "nowrap"; // ensure text doesn't wrap

  // Append to body, measure width, then remove
  document.body.appendChild(span);
  const width = span.offsetWidth;
  document.body.removeChild(span);

  return width;
}

export function calculateResizedState(
  tableContainer: HTMLElement,
  currentState: any
): any {
  const MinWidth = 65;

  const tableState = currentState === undefined ? {} : { ...currentState };
  if (tableState.columns === undefined) tableState.columns = {};
  if (tableState.columns.dimensions === undefined)
    tableState.columns.dimensions = {};

  const allCells: HTMLElement[] = Array.from(
    tableContainer.querySelectorAll(`.table-cell`)
  );
  const columnNames = new Set(
    allCells.map((element) => element.getAttribute("data-column-name") || "")
  );
  const cellsByColumn: Record<string, HTMLElement[]> = {};
  columnNames.forEach((column) => (cellsByColumn[column] = []));
  allCells.forEach((cell) => {
    const columnName = cell.getAttribute("data-column-name") || "";
    cellsByColumn[columnName].push(cell);
  });

  const getElementsWidth = (element: HTMLElement) => {
    return estimateWidth(element.innerText, fontSizeInPixels) + 20;
  };

  const fontSizeInPixels = parseFloat(
    window.getComputedStyle(allCells[0], null).getPropertyValue("font-size")
  );
  columnNames.forEach((column) => {
    const children = cellsByColumn[column];
    const maxWidth = Math.max(
      MinWidth,
      estimateWidth(
        TranslationManager.getTranslation().Columns[column],
        fontSizeInPixels
      ) + 20,
      ...Array.from(children).map((child) => getElementsWidth(child))
    );

    tableState.columns.dimensions[column] = {
      maxWidth: -1,
      MinWidth,
      width: maxWidth,
      flex: 0,
    };
  });

  return tableState;
}
