import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import {
  Dialog,
  FormControl,
  InputLabel,
} from "@mui/material";
import { TranslationManager } from "../../Translation/Translation";
import { DialogStyleContainer } from "../Header/Trading";
import ClearAllButton from "../UI-Elements/ClearAllButton";
import Button from "../UI-Elements/Button";
import { FatFingerCheck, OrderMessage, PriceInfo } from "../../Types/Websocket";
import { AppStateManager } from "../../StateManager";
import ColorSelect from "../UI-Elements/FormikInputs/SideSelector";
import Dropdown from "../UI-Elements/FormikInputs/DropdownSelect";
import SymbolSelectorWrapper from "../UI-Elements/FormikInputs/SymbolSelectorWrapper";
import NumberInput, { cleanValue, cleanValueWithTickerFormatting } from "../UI-Elements/FormikInputs/NumberInput";
import { OrderSubmitRequest } from "../../Types/private-data/NewOrderSubmitRequest";
import { TimeInForce } from "../../Types/private-data/timeInForce";
import { Side } from "../../Types/private-data/side";
import { OrderType } from "../../Types/private-data/orderType";
import DoubleCheckModal from "./DoubleCheckModal";
import { excludeKeys, randomString16Char } from "../../Utils/Common";
import { LMDInterface } from "../../KodiInterface/LMD";
import { Subscription } from "rxjs";

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string | null) => void;
  modifyOrder?: OrderMessage | null;

  initialSymbol?: string | null;
  price?: string;
  side?: string;
  quantity?: number;
}

// Define validation schema
const validationSchema = Yup.object().shape({
  side: Yup.string()
    .oneOf(Object.values(Side), 'Invalid side')
    .required("Required"),
  quantity: Yup.string()
    .required("Required")
    .test('is-greater-than-zero', 'Price must be greater than 0', value => {
      const number = parseFloat(value.replace(/,/g, '.').replace(/\./g, ''));
      return number > 0;
    })
    .test('is-number', 'Price must be a number', value => {
      // const replaceThisChar = TranslationManager.getActiveLanguage() === "EN" ? "," : ".";
      const number = parseFloat(value.replace(/,/g, '.').replace(/\./g, ''));
      return !isNaN(number);
    }),
  symbol: Yup.string().required("Required"),
  price: Yup.string()
    .when('order_type', (order_type, schema) => {
      if (order_type.toString() === 'market') {
        return schema; // don't require the price
      } else {
        return schema.required("Required") // require the price
          .test('is-greater-than-zero', 'Price must be greater than 0', value => {
            const number = parseFloat(value.replace(/,/g, '.').replace(/\./g, ''));
            return number > 0;
          })
          .test('is-number', 'Price must be a number', value => {
            const number = parseFloat(value.replace(/,/g, '.').replace(/\./g, ''));
            return !isNaN(number);
          });
      }
    }),
  order_type: Yup.string()
    .oneOf(Object.values(OrderType), 'Invalid order type')
    .required("Required"),
  visible_quantity: Yup.string()
    .when('hidden', (hidden, schema) => {
      return hidden
        ? schema.test('is-greater-than-zero', 'Visible quantity must be greater than 0', value => {
          if (value === null || value === undefined) {
            return true; // pass validation if value is null or undefined
          }
          const number = parseFloat(value.replace(/,/g, '.').replace(/\./g, ''));
          return number > 0;
        }).test('is-less-than-quantity', 'Visible quantity cannot be higher than quantity', function (value) {
          const { quantity } = this.parent;
          if (quantity === null || quantity === undefined || value === undefined || value === null) {
            return true; // pass validation if quantity is null or undefined
          }
          return value ? parseFloat(value.replace(/,/g, '.').replace(/\./g, '')) <= parseFloat(quantity.replace(/,/g, '.').replace(/\./g, '')) : false;
        })
        : schema;
    }),
  hidden: Yup.boolean(),
  time_in_force: Yup.string()
    .oneOf(Object.values(TimeInForce), 'Invalid time in force')
    .required('Required'),
  min_quantity: Yup.string(),
  broker: Yup.string().required("Required"),
});

const NewOrderModal: React.FC<SimpleDialogProps> = ({
  onClose,
  open,
  initialSymbol,
  modifyOrder = null,
  price,
  side,
  quantity
}) => {
  const [phaseTwo, setPhaseTwo] = React.useState<OrderSubmitRequest | undefined>(undefined);
  const [check, setCheck] = React.useState<FatFingerCheck | undefined>(undefined);
  const [priceInfo, setPriceInfo] = React.useState<IPriceInfoCallBack>({ ask: undefined, bid: undefined, last: undefined });

  const private_data: { value: string, title: string }[] = Object.entries(AppStateManager.PDS.PDSsockets).map((item) => ({ value: new URL(item[1].url).host, title: item[1].name ?? "" }))

  const handleModifyOrderBroker = (): string => {
    if (modifyOrder) {
      const broker = private_data.find(item => item.title === modifyOrder.broker);
      if (broker) {
        return broker.value;
      }
    }
    return ""
  }

  const initialValues = modifyOrder ? {
    side: modifyOrder.side,
    quantity: modifyOrder.quantity.toString(),
    symbol: modifyOrder.symbol,
    price: cleanValueWithTickerFormatting(modifyOrder?.price),
    order_type: modifyOrder.order_type,
    visible_quantity: modifyOrder.visible_quantity !== modifyOrder.quantity ? modifyOrder.visible_quantity?.toString() : undefined,
    hidden: modifyOrder.visible_quantity !== undefined && modifyOrder.visible_quantity !== modifyOrder.quantity ? true : false,
    time_in_force: modifyOrder.time_in_force,
    min_quantity: undefined,
    broker: handleModifyOrderBroker()
  } : {
    side: side ?? "buy",
    quantity: quantity?.toString() ?? null,
    symbol: initialSymbol || "",
    price: price !== undefined ? cleanValueWithTickerFormatting(Number(price)) : undefined,
    order_type: "limit",
    visible_quantity: undefined,
    hidden: false,
    time_in_force: "day",
    min_quantity: undefined,
    broker: (private_data !== undefined && private_data.length > 0) ? private_data[0].value : ""
  }

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  const sendOrder = async (values: OrderSubmitRequest, callback: (result: any) => void) => {
    //construct body
    Object.keys(values).forEach((key) => {
      if (values[key] === null || values[key] === "" || typeof values[key] === 'undefined') {
        delete values[key];
      }
    });
    const url = `https://${values.broker}/kodi/pds/dma/1.0.0/api/orders`;
    // Delete broker from values if it exists
    const json = JSON.stringify(excludeKeys(values, ['broker']) as OrderSubmitRequest);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await AppStateManager.getToken()}`
      },
      body: json
    })
      .then(response => { return response.json() })
      .then(data => {
        callback(data); // Call the callback function with the result
      })
      .catch((error) => {
        console.error('Error:', error);
        callback({ reason: 'Something went wrong' }); // Call the callback function with null in case of an error
      });
  }

  const updateOrder = async (values: OrderSubmitRequest, callback: (result: any) => void) => {
    await LMDInterface.updateOrder(modifyOrder?.broker, modifyOrder?.id ?? "", values.price, values.quantity)
      .then(data => {
        if (data.response === "success") { data.response = "updated" }
        callback(data); // Call the callback function with the result
      })
      .catch((error) => {
        let errorText = 'Unknown error';

        if (typeof error === 'string') {
          try {
            const errorObj = JSON.parse(error);
            errorText = errorObj.text || errorText;
            callback(errorObj)
          } catch (e) {
            // If parsing fails, extract the text property from the string
            const match = error.match(/"text":"(.*?)"/);
            if (match && match[1]) {
              errorText = match[1];
            }
          }
        } else if (error.text) {
          errorText = error.text;
          callback({ reason: errorText });
        }
      });
  }

  return (
    <Dialog
      onClose={() => {
        onClose(null);
        setPhaseTwo(undefined);
      }}
      open={open}
    >
      <div className="KM_modal KM_NewOrderModal" style={DialogStyleContainer}>
        <div className="jc-sb">
          <h1>{modifyOrder !== null ? TranslationManager.getTranslation().Titles.ModifyOrderTitle : TranslationManager.getTranslation().Titles.NewOrderTitle}</h1>
          <FontAwesomeIcon
            className="modalCloseButton"
            onClick={() => { onClose(null); setPhaseTwo(undefined) }}
            icon={faXmark}
          />
        </div>
        {
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              //we dont use this submit
              false && console.log("submit");
            }}
          >
            {({ touched, setFieldValue, values, validateForm, setErrors, setTouched }) => {

              return (
                phaseTwo ? (<DoubleCheckModal
                  fatFingerCheck={check}
                  goBack={() => {
                    setPhaseTwo(undefined);
                    //@ts-ignore
                  }} confirm={modifyOrder === null ? sendOrder : updateOrder} close={(e) => { onClose(e) }} data={phaseTwo} />
                ) :
                  <Form>
                    <div className="modal_body">
                      <div className="modal_item">
                        <FormControl fullWidth>
                          <InputLabel id="side-label">{TranslationManager.getTranslation().New_Order_Modal.Labels.Buy_sell}</InputLabel>
                          <Field
                            disabled={modifyOrder !== null}
                            as={ColorSelect}
                            name="side"
                            labelId="side-label"
                          />
                        </FormControl>
                        <ErrorMessage name="side" component="div" />
                      </div>
                      <div className="modal_item">
                        {/* SYMBOL */}
                        <Field
                          disabled={modifyOrder !== null}
                          // type="text"
                          as={SymbolSelectorWrapper}
                          size="md"
                          placeholder={TranslationManager.getTranslation().New_Order_Modal.Labels.Autocomplete_symbol}
                          // placeHolder="Instrument"
                          name="symbol"
                          disableClearable={false}
                          selectedSymbol={values.symbol}
                          onSelect={(symbol) => setFieldValue("symbol", symbol)}
                          autoFocus
                        />
                      </div>
                      <div className="modal_item">
                        {/* QUANTITY */}
                        <Field
                          inputSize="sm"
                          as={NumberInput}
                          name="quantity"
                          type="number"
                          decimalScale={0}
                          label={TranslationManager.getTranslation().New_Order_Modal.Labels.Quantity}
                        />
                      </div>

                      <div className="modal_item">
                        {/* PRICE */}
                        <Field
                          inputSize="sm"
                          as={NumberInput}
                          name="price"
                          disabled={values.order_type === "market"}
                          type="number"
                          withtickerformatting={true}
                          label={TranslationManager.getTranslation().New_Order_Modal.Labels.Price}
                        />
                      </div>
                      <div className="modal_item">
                        <FormControl fullWidth>
                          <InputLabel id="order_type-label">{TranslationManager.getTranslation().New_Order_Modal.Labels.Order_type}</InputLabel>
                          <Field
                            disabled={modifyOrder !== null}
                            fullWidth
                            menuItems={[
                              { value: "limit", title: "Limit" },
                              { value: "market", title: "Market" },
                              // { value: "loo", title: "LOO" },
                              // { value: "moo", title: "MOO" },
                              // { value: "loc", title: "LOC" },
                              // { value: "moc", title: "MOC" },
                              // { value: "iooc", title: "IOOC" },
                              // { value: "ioop", title: "IOOP" },
                            ]}
                            as={Dropdown}
                            name="order_type"
                            labelId="order_type-label"
                            onChange={(value) => {
                              value === "market" && setFieldValue("price", undefined);
                            }}
                          />
                        </FormControl>
                        <ErrorMessage className="formik-error-message" name="order_type" component="div" />
                      </div>
                      <div className="modal_item">
                        {/* Visbile QTY */}
                        <Field
                          inputSize="sm"
                          as={NumberInput}
                          name="visible_quantity"
                          disabled={!values.hidden || modifyOrder !== null}
                          type="number"
                          decimalScale={0}
                          label={TranslationManager.getTranslation().New_Order_Modal.Labels.Visible_quantity}
                        />
                      </div>
                      <div className="hidden">
                        {/* HIDDEN */}
                        <label><Field disabled={modifyOrder !== null} type="checkbox" name="hidden" />{TranslationManager.getTranslation().New_Order_Modal.Labels.Hidden}</label>
                      </div>
                      <div className="modal_item">
                        <FormControl fullWidth>
                          <InputLabel id="time_in_force-label">{TranslationManager.getTranslation().New_Order_Modal.Labels.Time_in_force}</InputLabel>
                          <Field
                            fullWidth
                            menuItems={[
                              { value: "day", title: "Day" },
                              // { value: "fill_or_kill", title: "FOK" },
                              { value: "immediate_or_cancel", title: "IOC" },
                              // { value: "good_till_cancel", title: "GTC" },
                              // { value: "good_till_date", title: "GTT" },
                            ]}
                            as={Dropdown}
                            name="time_in_force"
                            disabled={true}
                            labelId="time_in_force-label"
                          />
                        </FormControl>
                        <ErrorMessage className="formik-error-message" name="time_in_force" component="div" />
                      </div>
                      <div className="modal_item">
                        {/* Min QTY */}
                        <Field
                          inputSize="sm"
                          as={NumberInput}
                          name="min_quantity"
                          type="number"
                          disabled
                          decimalScale={0}
                          label={TranslationManager.getTranslation().New_Order_Modal.Labels.Min_quantity}
                        />
                      </div>
                      <div className="modal_item">
                        {/* // connect to available brokers  */}
                        <FormControl fullWidth>
                          <InputLabel id="broker-label">{TranslationManager.getTranslation().New_Order_Modal.Labels.Broker}</InputLabel>
                          <Field
                            fullWidth
                            disabled={modifyOrder !== null}
                            menuItems={private_data}
                            as={Dropdown}
                            name="broker"
                            labelId="broker-label"
                          />
                        </FormControl>
                        <ErrorMessage className="formik-error-message" name="broker" component="div" />
                      </div>
                    </div>
                    <div className="button-container">
                      <div>
                        <ClearAllButton />
                        <Button size="sm" type="button" buttonType="primary" onClick={async () => {
                          validateForm().then(async (errors) => {
                            if (Object.keys(errors).length === 0) {
                              const price = Number(cleanValue(values.price ?? '').replace(",", "."))
                              let check;
                              try {
                                check = values.order_type !== "market" ? await LMDInterface.fatFingerCheck(values.symbol, price ?? 0, values.side) : undefined;
                              } catch (e) {
                                check = 'no_data'
                              }
                              setCheck(check);
                              //check if values.hidden is false. then vis_qty should be the same as qty
                              //else vis_qty should be the same as visible_quantity
                              const vis_qty = values.order_type === 'market'
                                ? 0
                                : values.hidden
                                  ? values.visible_quantity !== undefined && values.visible_quantity !== null && values.visible_quantity !== "" && values.visible_quantity !== "0"
                                    ? Number(cleanValue(values.visible_quantity))
                                    : undefined
                                  : undefined;

                              const valuesCopy: OrderSubmitRequest = {
                                reference_id: randomString16Char(),
                                side: values.side as Side,
                                symbol: values.symbol,
                                order_type: values.order_type as OrderType,
                                time_in_force: values.time_in_force as TimeInForce,
                                broker: values.broker,
                                reference_best_ask: priceInfo.ask,
                                reference_best_bid: priceInfo.bid,
                                reference_last_price: priceInfo.last,
                                ...(vis_qty !== undefined ? { visible_quantity: vis_qty } : {}),
                                //@ts-ignore
                                ...(values.quantity !== undefined && values.quantity !== null ? { quantity: Number(parseFloat(values.quantity.replace(/,/g, '.').replace(/\./g, ''))) } : {}),
                                ...(values.price !== undefined ? { price: price } : {}),
                                // ...(values.hidden !== undefined ? { hidden: values.hidden } : {}),
                                // ...(values.min_quantity !== undefined ? { min_quantity: values.min_quantity } : {}),
                              };
                              setPhaseTwo(valuesCopy);
                            } else {
                              // If there are validation errors, set the form errors and touched state
                              setErrors(errors);
                              setTouched({
                                ...touched,
                                ...Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
                              });
                            }
                          });
                        }}>OK</Button>
                        <Button size="sm" type="button" buttonType="secondary" onClick={() => onClose(null)}>{TranslationManager.getTranslation().Buttons.Cancel}</Button>
                      </div>
                      <div className="symbol-info-section">
                        <SymbolInfo onPriceInfoChange={(e) => setPriceInfo(e)} symbol={values.symbol} />
                      </div>
                    </div>
                  </Form>
              )
            }}
          </Formik>
        }

      </div>
    </Dialog >
  );
};

export default NewOrderModal;

interface IPriceInfoCallBack {
  ask: number | undefined,
  bid: number | undefined,
  last: number | undefined,
}


//SYMBOLINFO
class SymbolInfo extends React.Component<{ symbol: string, onPriceInfoChange(e: IPriceInfoCallBack): void }, { priceInfo?: PriceInfo | undefined }> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.state = { priceInfo: undefined };
    this.subscriptions = [];
  }


  async componentDidMount(): Promise<void> {
    await this.subscribeToSymbol();
  }

  async componentDidUpdate(prevProps): Promise<void> {
    if (this.props.symbol !== prevProps.symbol) {
      await this.subscribeToSymbol();
    }
  }

  private clearSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  subscribeToSymbol = async () => {
    this.clearSubscriptions();
    this.subscriptions.push(
      AppStateManager.MF.getHandler("price_info").subscribe(
        this.props.symbol,
        (priceInfo: PriceInfo | undefined) => {
          this.setState({ priceInfo });
          this.props.onPriceInfoChange({ ask: priceInfo?.ask_price, bid: priceInfo?.bid_price, last: priceInfo?.last_price ?? priceInfo?.previous_closing_price }); // Call the callback function with the new priceInfo
        }
      )
    );
  }

  render() {

    const { priceInfo } = this.state;
    return (
      (this.props.symbol) ? <>
        <div>
          <span className="info-title">{TranslationManager.getTranslation().New_Order_Modal.Symbol_info.Last}</span>
          <span className="info-value">{priceInfo?.last_price ?? priceInfo?.previous_closing_price ?? '-'}</span>
        </div>
        <div>
          <span className="info-title">{TranslationManager.getTranslation().New_Order_Modal.Symbol_info.Bid}</span>
          <span className="info-value">{priceInfo?.bid_price ?? '-'} {(priceInfo?.bid_quantity) ? `(${priceInfo.bid_quantity})` : ''}</span>
        </div>
        <div>
          <span className="info-title">{TranslationManager.getTranslation().New_Order_Modal.Symbol_info.Ask}</span>
          <span className="info-value">{priceInfo?.ask_price ?? '-'} {(priceInfo?.ask_quantity) ? `(${priceInfo.ask_quantity})` : ''}</span>
        </div>
      </> : <div style={{ height: '21px' }}></div>
    )
  }
}