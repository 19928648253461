import { MenuItem, Select } from "@mui/material";
import cx from "classnames";
import React from "react";
interface IProps {
    label?: string;
    labelId?: string;
    height?: string;
    width?: string;
}
const Dropdown: React.FC<IProps & { menuItems: { value: string, title: string }[] }> = ({ menuItems, label, height, width, ...props }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    return (
        <Select
            sx={{ height: height ?? '30px', width: width ?? '100%' }}
            labelId={props.labelId}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            style={{
                borderRadius: isOpen ? '6px 6px 0 0 ' : '6px'
            }}
            defaultValue={menuItems[0].value}
            className={cx('formik-dropdown', { 'isOpen': isOpen })}
            MenuProps={{
                PaperProps: {
                    elevation: 5
                }
            }}
        >
            {
                menuItems.map((item) => <MenuItem value={item.value}>{item.title}</MenuItem>)
            }
        </Select >
    );
};
export default Dropdown;
