import { hideDefaultModal, openDefaultModal } from "../Components/Main/Main";
import NewOrderModal from "../Components/Modals/NewOrderModal";
import { GLWindowManager } from "../Components/Windows/GLWindowManager";

export enum WSHotkeysTypes {
    ShiftR = 'shift+R',
    Shift1 = 'shift+1',
    Shift2 = 'shift+2',
    Shift3 = 'shift+3',
    Shift4 = 'shift+4',
    Shift5 = 'shift+5',
    Shift6 = 'shift+6',
    ShiftDown1 = 'shift+down+1',
    ShiftDown2 = 'shift+down+2',
    ShiftDown3 = 'shift+down+3',
    ShiftDown4 = 'shift+down+4',
    ShiftDown5 = 'shift+down+5',
    ShiftDown6 = 'shift+down+6',
    ShiftUp1 = 'shift+up+1',
    ShiftUp2 = 'shift+up+2',
    ShiftUp3 = 'shift+up+3',
    ShiftUp4 = 'shift+up+4',
    ShiftUp5 = 'shift+up+5',
    ShiftUp6 = 'shift+up+6',
    ShiftLeft1 = 'shift+left+1',
    ShiftLeft2 = 'shift+left+2',
    ShiftLeft3 = 'shift+left+3',
    ShiftLeft4 = 'shift+left+4',
    ShiftLeft5 = 'shift+left+5',
    ShiftLeft6 = 'shift+left+6',
    ShiftRight1 = 'shift+right+1',
    ShiftRight2 = 'shift+right+2',
    ShiftRight3 = 'shift+right+3',
    ShiftRight4 = 'shift+right+4',
    ShiftRight5 = 'shift+right+5',
    ShiftRight6 = 'shift+right+6'
}

export const WSHotkeys: WSHotkeysTypes[] = [
    WSHotkeysTypes.ShiftR,
    WSHotkeysTypes.Shift1,
    WSHotkeysTypes.Shift2,
    WSHotkeysTypes.Shift3,
    WSHotkeysTypes.Shift4,
    WSHotkeysTypes.Shift5,
    WSHotkeysTypes.Shift6,
    WSHotkeysTypes.ShiftDown1,
    WSHotkeysTypes.ShiftDown2,
    WSHotkeysTypes.ShiftDown3,
    WSHotkeysTypes.ShiftDown4,
    WSHotkeysTypes.ShiftDown5,
    WSHotkeysTypes.ShiftDown6,
    WSHotkeysTypes.ShiftUp1,
    WSHotkeysTypes.ShiftUp2,
    WSHotkeysTypes.ShiftUp3,
    WSHotkeysTypes.ShiftUp4,
    WSHotkeysTypes.ShiftUp5,
    WSHotkeysTypes.ShiftUp6,
    WSHotkeysTypes.ShiftLeft1,
    WSHotkeysTypes.ShiftLeft2,
    WSHotkeysTypes.ShiftLeft3,
    WSHotkeysTypes.ShiftLeft4,
    WSHotkeysTypes.ShiftLeft5,
    WSHotkeysTypes.ShiftLeft6,
    WSHotkeysTypes.ShiftRight1,
    WSHotkeysTypes.ShiftRight2,
    WSHotkeysTypes.ShiftRight3,
    WSHotkeysTypes.ShiftRight4,
    WSHotkeysTypes.ShiftRight5,
    WSHotkeysTypes.ShiftRight6
];

export enum TradingHotkeysTypes {
    ShiftN = 'shift+N',
    Shift8 = 'shift+8',
    Shift9 = 'shift+9',
    ShiftDown8 = 'shift+down+8',
    ShiftDown9 = 'shift+down+9',
    ShiftUp8 = 'shift+up+8',
    ShiftUp9 = 'shift+up+9',
    ShiftLeft8 = 'shift+left+8',
    ShiftLeft9 = 'shift+left+9',
    ShiftRight8 = 'shift+right+8',
    ShiftRight9 = 'shift+right+9'
}

export const TradingHotkeys: TradingHotkeysTypes[] = [
    TradingHotkeysTypes.ShiftN,
    TradingHotkeysTypes.Shift8,
    TradingHotkeysTypes.Shift9,
    TradingHotkeysTypes.ShiftDown8,
    TradingHotkeysTypes.ShiftDown9,
    TradingHotkeysTypes.ShiftUp8,
    TradingHotkeysTypes.ShiftUp9,
    TradingHotkeysTypes.ShiftLeft8,
    TradingHotkeysTypes.ShiftLeft9,
    TradingHotkeysTypes.ShiftRight8,
    TradingHotkeysTypes.ShiftRight9
];

export const hotkeyActions = {
    [WSHotkeysTypes.ShiftR]: () => { GLWindowManager.reset(); },
    [WSHotkeysTypes.Shift1]: () => GLWindowManager.addComponentToRoot('MarketDepth', 'left'),
    [WSHotkeysTypes.Shift2]: () => GLWindowManager.addComponentToRoot('TradeTicker', 'left'),
    [WSHotkeysTypes.Shift3]: () => GLWindowManager.addComponentToRoot('WatchList', 'left'),
    [WSHotkeysTypes.Shift4]: () => GLWindowManager.addComponentToRoot('Chart', 'left'),
    [WSHotkeysTypes.Shift5]: () => GLWindowManager.addComponentToRoot('News', 'left'),
    [WSHotkeysTypes.Shift6]: () => GLWindowManager.addComponentToRoot('PriceLadder', 'left'),
    [WSHotkeysTypes.ShiftDown1]: () => GLWindowManager.addComponentToRoot('MarketDepth', 'bottom'),
    [WSHotkeysTypes.ShiftDown2]: () => GLWindowManager.addComponentToRoot('TradeTicker', 'bottom'),
    [WSHotkeysTypes.ShiftDown3]: () => GLWindowManager.addComponentToRoot('WatchList', 'bottom'),
    [WSHotkeysTypes.ShiftDown4]: () => GLWindowManager.addComponentToRoot('Chart', 'bottom'),
    [WSHotkeysTypes.ShiftDown5]: () => GLWindowManager.addComponentToRoot('News', 'bottom'),
    [WSHotkeysTypes.ShiftDown6]: () => GLWindowManager.addComponentToRoot('PriceLadder', 'bottom'),
    [WSHotkeysTypes.ShiftUp1]: () => GLWindowManager.addComponentToRoot('MarketDepth', 'top'),
    [WSHotkeysTypes.ShiftUp2]: () => GLWindowManager.addComponentToRoot('TradeTicker', 'top'),
    [WSHotkeysTypes.ShiftUp3]: () => GLWindowManager.addComponentToRoot('WatchList', 'top'),
    [WSHotkeysTypes.ShiftUp4]: () => GLWindowManager.addComponentToRoot('Chart', 'top'),
    [WSHotkeysTypes.ShiftUp5]: () => GLWindowManager.addComponentToRoot('News', 'top'),
    [WSHotkeysTypes.ShiftUp6]: () => GLWindowManager.addComponentToRoot('PriceLadder', 'top'),
    [WSHotkeysTypes.ShiftLeft1]: () => GLWindowManager.addComponentToRoot('MarketDepth', 'left'),
    [WSHotkeysTypes.ShiftLeft2]: () => GLWindowManager.addComponentToRoot('TradeTicker', 'left'),
    [WSHotkeysTypes.ShiftLeft3]: () => GLWindowManager.addComponentToRoot('WatchList', 'left'),
    [WSHotkeysTypes.ShiftLeft4]: () => GLWindowManager.addComponentToRoot('Chart', 'left'),
    [WSHotkeysTypes.ShiftLeft5]: () => GLWindowManager.addComponentToRoot('News', 'left'),
    [WSHotkeysTypes.ShiftLeft6]: () => GLWindowManager.addComponentToRoot('PriceLadder', 'left'),
    [WSHotkeysTypes.ShiftRight1]: () => GLWindowManager.addComponentToRoot('MarketDepth', 'right'),
    [WSHotkeysTypes.ShiftRight2]: () => GLWindowManager.addComponentToRoot('TradeTicker', 'right'),
    [WSHotkeysTypes.ShiftRight3]: () => GLWindowManager.addComponentToRoot('WatchList', 'right'),
    [WSHotkeysTypes.ShiftRight4]: () => GLWindowManager.addComponentToRoot('Chart', 'right'),
    [WSHotkeysTypes.ShiftRight5]: () => GLWindowManager.addComponentToRoot('News', 'right'),
    [WSHotkeysTypes.ShiftRight6]: () => GLWindowManager.addComponentToRoot('PriceLadder', 'right'),


    [TradingHotkeysTypes.ShiftN]: (event) => { event.preventDefault(); openDefaultModal(<NewOrderModal open={true} onClose={hideDefaultModal} />); },
    [TradingHotkeysTypes.Shift8]: () => GLWindowManager.addComponentToRoot('ViewOrders'),
    [TradingHotkeysTypes.Shift9]: () => GLWindowManager.addComponentToRoot('ViewTrades'),
    [TradingHotkeysTypes.ShiftDown8]: () => GLWindowManager.addComponentToRoot('ViewOrders', 'bottom'),
    [TradingHotkeysTypes.ShiftDown9]: () => GLWindowManager.addComponentToRoot('ViewTrades', 'bottom'),
    [TradingHotkeysTypes.ShiftUp8]: () => GLWindowManager.addComponentToRoot('ViewOrders', 'top'),
    [TradingHotkeysTypes.ShiftUp9]: () => GLWindowManager.addComponentToRoot('ViewTrades', 'top'),
    [TradingHotkeysTypes.ShiftLeft8]: () => GLWindowManager.addComponentToRoot('ViewOrders', 'left'),
    [TradingHotkeysTypes.ShiftLeft9]: () => GLWindowManager.addComponentToRoot('ViewTrades', 'left'),
    [TradingHotkeysTypes.ShiftRight8]: () => GLWindowManager.addComponentToRoot('ViewOrders', 'right'),
    [TradingHotkeysTypes.ShiftRight9]: () => GLWindowManager.addComponentToRoot('ViewTrades', 'right')


};
