// Package imports:
import React from 'react';

export type FlagNames = 'ISK' | 'EUR' | 'USD' | 'GBP' | 'DKK' | 'SEK' | 'NOK' | 'JPY' | 'CHF' | 'CAD' | 'CAD' | 'OMX' | 'XDR' | 'KIB' | 'FIN';

interface IProps {
    name: FlagNames,
};

const Flag: React.FC<IProps> = ({name}) => {
    return (
        <div className="KCL_flag">
            <span className='grayBackground'>
                <img
                    src={`https://cdn.livemarketdata.com/Avatars/Flags/${name}.svg`}
                    alt={name}
                    width="20"
                    height="20"
                />
            </span>
        </div>
    );
}

export default Flag;