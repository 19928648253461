import React, { Component, useEffect } from 'react';
import cx from 'classnames';
import { JSONObject } from '../../../Types/Common';
import { Subscription } from 'rxjs';
import { createPromise } from '../../../Utils/Common';

import { GridColDef, DataGridPro, useGridApiRef, GridRenderCellParams, GridEventListener } from '@mui/x-data-grid-pro';
// This import is... I'm lost for words. Lucky?.
// I just guessed names did not find it in any documentation
// Enables subscriptions only of rendered symbols
import { GridRow } from '@mui/x-data-grid-pro';

import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LMDInterface } from '../../../KodiInterface/LMD';
import { WatchListColumns } from './Columns';
import { GLWindowManager } from '../GLWindowManager';
import { hideDefaultModal, openDefaultModal, openPropertiesOfSymbol } from '../../Main/Main';
import { ListInfo, TradeableInfo } from '../../../Types/LMDTypes';
import { formatWatchListCell } from './CellFormat';
import { PriceInfo } from '../../../Types/Websocket';


import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
    GridColumnMenu,
    GridColumnMenuProps,
    GridColumnMenuItemProps,
} from '@mui/x-data-grid';

import IsoIcon from '@mui/icons-material/Iso';
import { TranslationManager } from '../../../Translation/Translation';
import { AppStateManager, getHandler } from '../../../StateManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faLayerGroup, faChartLine, faCaretDown, faCaretUp, faMoneyCheckPen } from '@fortawesome/pro-solid-svg-icons';
import NewOrderModal from '../../Modals/NewOrderModal';
import { formatNumber } from '../../../Utils/Formatting';
import { cleanValueWithTickerFormatting } from '../../UI-Elements/FormikInputs/NumberInput';
type GridInitialStatePro = JSONObject;

type Row = { id: string } & PriceInfo & TradeableInfo;

type Input = {
    list: ListInfo | undefined,

    tableState: GridInitialStatePro | undefined,
    saveState: (state: GridInitialStatePro) => void,

    rowsSelectable: boolean,
    onRowClick: GridEventListener<'rowClick'>,
}

export function WatchListTable(props: Input) {
    const { tableState, saveState, onRowClick, rowsSelectable } = props;
    const apiRef = useGridApiRef();
    const [rows, updateRows] = React.useState([] as Row[]);
    const [list, updateList] = React.useState(props.list);
    // Context menu
    const [contextMenuPosition, setContextMenuPosition] = React.useState<{ x: number, y: number } | null>(null);
    const [selectedSymbol, setSelectedSymbol] = React.useState<string | null>(null);
    const [selectedRow, setSelectedRow] = React.useState<PriceInfo>();
    const [selectedColumn, setSelectedColumn] = React.useState<string | null>(null);
    const [newOrderModalOpen, setNewOrderModalOpen] = React.useState(false);

    // For resize
    const ref = React.createRef<any>();

    // Set initial rows if they have not already been set
    // Logic:
    // If first time being rendered get all symbols, for each symbol subscribe to priceInfoHandler
    // untill you get a single update and then unsubsribe. Wait untill all symbols have given a single
    // update. Use these updates to create inital rows
    if (list !== undefined && rows.length === 0) LMDInterface.getTradeableListSymbols(list.symbol).then(symbols =>
        Promise.all(symbols.map(async symbol => {
            const [priceInfo/*, tradableInfo*/] = await Promise.all([
                new Promise<PriceInfo>((resolve) => {
                    //There was a race condition here that was solved with promise and await
                    const [subscriptionPromise, subscriptionResolve] = createPromise<Subscription>();
                    subscriptionResolve(getHandler('price_info').subscribe(symbol, async symbolInfo => {
                        // Wait for actual update not the first empty update
                        if (symbolInfo !== undefined) {
                            if (symbolInfo.last_price === undefined) symbolInfo.last_price = symbolInfo.previous_closing_price;
                            resolve(symbolInfo);
                            // Delay allows rendered rows to subscribe before this unsubscribe,
                            // meaning no unsubscribe messages over the wire for rendered symbols
                            subscriptionPromise.then(subscription => setTimeout(() => subscription.unsubscribe(), 500));
                        } else {
                            // If the symbol is not available, resolve with not_available
                            resolve({ symbol: symbol, orderbook_state: 'not_available' } as PriceInfo);
                            // if the symbolInfo is undefined, that means the symbol is no longer active and we should unsubscribe
                            subscriptionPromise.then(subscription => setTimeout(() => subscription.unsubscribe(), 500));
                            // subscriptionPromise.then(subscription => subscription.unsubscribe());
                        }
                    }));

                }),
                //LMDInterface.getTradeableInfo(symbol)
            ]);
            const row = { ...priceInfo, /*...tradableInfo,*/ id: priceInfo.symbol } as Row;
            return row;
        })).then(rows => updateRows(rows))
    );


    // Set default columns and initialize state
    const columnVisibilityModel = {};
    WatchListColumns.forEach(({ name, defaultHidden }) => { if (defaultHidden) columnVisibilityModel[name] = false });
    const state = tableState === undefined ? { columns: { columnVisibilityModel } } : tableState;
    if (tableState === undefined) saveState(state);
    React.useEffect(() => {
        if (apiRef.current !== null) {
            apiRef.current.restoreState(state);
        }
    });

    // Save state logic
    function onChange() {
        const state = apiRef.current.exportState() as GridInitialStatePro;
        delete state['preferencePanel'];
        saveState(state);
    }

    // Trigger update on listSymbol change
    if (list?.symbol !== props.list?.symbol) {
        updateRows([]);
        updateList(props.list);
    }

    // Reset selection on row selection change
    useEffect(() => apiRef.current.selectRows([], true, true), [rowsSelectable]);
    const [valueMemo, setValueMemo] = React.useState({});
    const [columns, setColumns] = React.useState<GridColDef[]>(WatchListColumns.map(({ name, dataType }) => {
        return {
            headerClassName: 'mui-header',
            cellClassName: 'mui-cell',
            hideable: name !== 'symbol',
            field: name,
            type: dataType === undefined ? 'number' : dataType,
            headerName: TranslationManager.getTranslation().Columns[name],
            //width: 100,
            renderCell: (params: GridRenderCellParams) => {
                const row = params.row; const symbol = row['symbol'];

                if (!(symbol in valueMemo)) valueMemo[symbol] = {};
                if (!(name in valueMemo[symbol])) valueMemo[symbol][name] = { prevValue: row[name], lastAnimationID: 1 };
                const { prevValue, lastAnimationID } = valueMemo[symbol][name];
                valueMemo[symbol][name] = { prevValue: row[name], lastAnimationID: 1 - lastAnimationID };
                return (<div className={cx(`watchTable-cell table-cell-${name}`, {})}>
                    {formatWatchListCell(name, row, prevValue, lastAnimationID, true)}
                </div>);
            },
            valueGetter: (params) => {
                if (name === 'change') return params.row['last_price_diff_closing_percent'];
                else return params.value;
            }
        };
    }));
    const OrderMenuItem: React.FC<{ side: string, quantity?: number, price?: number, label: string, color: string }> = ({ side, quantity, price, label, color }) => {
        return (
            <MenuItem onClick={() => {
                openDefaultModal(
                    <NewOrderModal
                        side={side}
                        initialSymbol={selectedSymbol}
                        quantity={quantity}
                        price={price?.toString()}
                        open={true} onClose={hideDefaultModal}
                    />
                );
                setContextMenuPosition(null)
            }
            }>
                <span style={{ color }}>{label}</span>
            </MenuItem >
        )
    };

    const OrderMenu = ({ side, quantity, price }) => {
        return (
            <div style={{
                borderBottom: '1px solid var(--dark-100)',
                borderTop: '1px solid var(--dark-100)',
                margin: '4px 0'
            }}>
                <OrderMenuItem
                    side='buy'
                    quantity={quantity}
                    price={price}
                    label={`${TranslationManager.getTranslation().Basic.Buy} ${formatNumber(quantity)}@${cleanValueWithTickerFormatting(Number(price))} ${side !== 'ask' ? `- ${TranslationManager.getTranslation().Basic.Fill.toLocaleUpperCase()}` : ''}`}
                    color='var(--green-600)'
                />
                <OrderMenuItem
                    side='sell'
                    quantity={quantity}
                    price={price}
                    label={`${TranslationManager.getTranslation().Basic.Sell} ${formatNumber(quantity)}@${cleanValueWithTickerFormatting(Number(price))} ${side !== 'bid' ? `- ${TranslationManager.getTranslation().Basic.Fill.toLocaleUpperCase()}` : ''}`}
                    color='var(--red-600)'
                />
            </div>
        );
    }

    const DefaultOrderMenu = () => (
        <div style={{
            borderBottom: '1px solid var(--dark-100)',
            borderTop: '1px solid var(--dark-100)',
            margin: '4px 0'
        }}>
            <OrderMenuItem
                side='buy'
                label={TranslationManager.getTranslation().Basic.Buy}
                color='var(--green-600)'
            />
            <OrderMenuItem
                side='sell'
                label={TranslationManager.getTranslation().Basic.Sell}
                color='var(--red-600)'
            />
        </div>
    );

    const handleBuySellContextMenu = () => {
        const isBidColumn = selectedColumn === 'bid_price' || selectedColumn === 'bid_quantity';
        const isAskColumn = selectedColumn === 'ask_price' || selectedColumn === 'ask_quantity';

        if (isBidColumn) {
            return (selectedRow?.bid_price && selectedRow?.bid_quantity && selectedRow.bid_price !== 0 && selectedRow.bid_quantity !== 0) ?
                <OrderMenu side='bid' quantity={selectedRow.bid_quantity} price={selectedRow.bid_price.toString()} /> :
                <DefaultOrderMenu />;
        }

        if (isAskColumn) {
            return (selectedRow?.ask_price && selectedRow?.ask_quantity && selectedRow.ask_price !== 0 && selectedRow.ask_quantity !== 0) ?
                <OrderMenu side='ask' quantity={selectedRow.ask_quantity} price={selectedRow.ask_price.toString()} /> :
                <DefaultOrderMenu />;
        }

        return null;
    };

    return (<>
        <DataGridPro
            ref={ref}
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            /* this is for the resize button to work better*/
            /* could be conditionally toggled for better performance*/
            columnBuffer={100}
            /*disableVirtualization*/
            rowHeight={25}
            columnHeaderHeight={25}
            throttleRowsMs={500}
            onSortModelChange={onChange}
            onColumnWidthChange={onChange}
            onColumnOrderChange={onChange}
            onColumnVisibilityModelChange={onChange}
            sx={{
                border: 'none',
                "& .MuiDataGrid-row:hover": {
                    backgroundColor: "var(--blue-100)"
                }
            }}
            disableRowSelectionOnClick={!rowsSelectable}
            onRowClick={onRowClick}
            hideFooter
            slots={{
                columnSortedAscendingIcon: function MyColumnSortedAscendingIcon() {
                    return <FontAwesomeIcon icon={faCaretUp} style={{ fontSize: '10px' }} />
                },
                columnSortedDescendingIcon: function MyColumnSortedDescendingIcon() {
                    return <FontAwesomeIcon icon={faCaretDown} style={{ fontSize: '10px' }} />
                },
                noRowsOverlay: function MyNoRowsOverlay() {
                    return <>{list !== undefined && <div className='fill center-container'><CircularProgress /></div>}</>;
                },
                columnMenu: function CustomColumnMenu(props: GridColumnMenuProps) {
                    return (
                        <GridColumnMenu
                            {...props}
                            slots={{
                                // Add new item
                                // RESIZE BUTTON
                                columnMenuUserItem: function CustomUserItem(props: GridColumnMenuItemProps) {
                                    const { myCustomHandler, myCustomValue } = props;
                                    return (
                                        <MenuItem onClick={myCustomHandler}>
                                            <ListItemIcon><IsoIcon fontSize="small" /></ListItemIcon>
                                            <ListItemText>{myCustomValue}</ListItemText>
                                        </MenuItem>
                                    );
                                },
                            }}
                            slotProps={{
                                columnMenuUserItem: {
                                    displayOrder: 15,
                                    myCustomValue: 'Resize',
                                    myCustomHandler: () => {
                                        if (ref.current !== null) {
                                            const tableParent = ref.current;
                                            const newTableState = calculateResizedState(tableParent, state);
                                            saveState(newTableState);
                                        }
                                    }
                                },
                            }}
                        />
                    );
                },
                row: (class MyRow extends Component<any> {
                    subscriptions: Subscription[];
                    hasUnmounted: boolean;
                    constructor(props) {
                        super(props);
                        this.subscriptions = [];
                        this.hasUnmounted = false;
                    }
                    componentDidMount(): void {
                        const symbol = this.props.rowId;
                        var row = {};
                        LMDInterface.getTradeableInfo(symbol).then(tradeableInfo => {
                            row = { ...row, ...tradeableInfo, id: symbol };
                            if (apiRef.current !== null && !this.hasUnmounted) apiRef.current.updateRows([row]);
                        });

                        this.subscriptions.push(getHandler('price_info').subscribe(symbol, symbolInfo => {
                            row = { ...row, ...symbolInfo, id: symbol };
                            if (apiRef.current !== null && !this.hasUnmounted) apiRef.current.updateRows([row]);
                        }));
                    }
                    componentWillUnmount(): void {
                        this.hasUnmounted = true;
                        // const symbol = this.props.rowId;
                        // In the event of closing many tabs really fast this function does
                        // not always seem to trigger, causing a memory leak
                        setTimeout(() => this.subscriptions.forEach(subscription => subscription.unsubscribe()), 1000);
                    }
                    render(): React.ReactNode {
                        return <GridRow {...this.props} />;
                    }
                }),
            }}

            slotProps={{
                cell: {
                    onContextMenu: (event: React.MouseEvent) => {
                        event.preventDefault();
                        // setContextMenuPosition({ x: event.clientX - 2, y: event.clientY - 4 });

                        const columnIndex = event.currentTarget.getAttribute("data-field");
                        if (columnIndex) {
                            // Get the column details using the column index
                            const column = apiRef.current.getColumn(columnIndex);
                            setSelectedColumn(column.field);
                        }
                        // setSelectedSymbol(id as string);
                    },
                },
                row: {
                    onContextMenu: (event: React.MouseEvent) => {
                        event.preventDefault();
                        setContextMenuPosition({ x: event.clientX - 2, y: event.clientY - 4 });
                        const id = event.currentTarget.getAttribute("data-id");
                        setSelectedSymbol(id);

                        const rowData = apiRef.current.getRowParams(id as string).row;
                        setSelectedRow(rowData);
                    },
                    style: { cursor: 'context-menu' },
                },
            }}
        />
        <Menu
            elevation={2}
            open={contextMenuPosition !== null}
            onClose={() => setContextMenuPosition(null)}

            anchorReference="anchorPosition"
            anchorPosition={contextMenuPosition !== null ? { top: contextMenuPosition.y, left: contextMenuPosition.x } : undefined}

            slotProps={{
                root: {
                    onContextMenu: (e) => {
                        e.preventDefault();
                        setContextMenuPosition(null);
                    }

                }
            }}
        >
            <div className='menu-symbol'>
                <span>{selectedSymbol}</span>
            </div>
            {
                AppStateManager.hasAccessToPDS && handleBuySellContextMenu()
            }
            {
                AppStateManager.hasAccessToPDS &&
                <MenuItem
                    onClick={() => {
                        if (selectedSymbol !== null) {
                            setNewOrderModalOpen(true);
                        }
                        setContextMenuPosition(null);
                    }}>
                    <FontAwesomeIcon icon={faMoneyCheckPen} style={{ marginRight: 8 }} />
                    {TranslationManager.getTranslation().WatchList.RightClickTableMenu.NewOrder}
                </MenuItem>
            }
            <MenuItem
                onClick={() => {
                    if (selectedSymbol !== null) openPropertiesOfSymbol(selectedSymbol);
                    setContextMenuPosition(null);
                }}>
                <FontAwesomeIcon icon={faGear} style={{ marginRight: 8 }} />
                {TranslationManager.getTranslation().WatchList.RightClickTableMenu.OrderbookProperties}
            </MenuItem>

            <MenuItem
                onClick={() => {
                    GLWindowManager.addComponentToRoot('MarketDepth', 'left', { symbol: selectedSymbol });
                    setContextMenuPosition(null);
                }}>
                <FontAwesomeIcon icon={faLayerGroup} style={{ marginRight: 8 }} />
                {TranslationManager.getTranslation().WatchList.RightClickTableMenu.MarketDepth}
            </MenuItem>

            <MenuItem
                onClick={() => {
                    GLWindowManager.addComponentToRoot('Chart', 'left', { symbol: selectedSymbol });
                    setContextMenuPosition(null);
                }}>
                <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 8 }} />
                {TranslationManager.getTranslation().WatchList.RightClickTableMenu.Chart}
            </MenuItem>
        </Menu>
        <NewOrderModal
            open={newOrderModalOpen}
            onClose={() => {
                setNewOrderModalOpen(false);
            }}
            initialSymbol={selectedSymbol}
        />
    </>);
}


// This function should be rewritten, does not really seem right, works for now...
function calculateResizedState(tableContainer: HTMLElement, currentState: any): any {
    const MinWidth = 65;

    var columnNames: string[], tableState;
    if (currentState === undefined) {
        tableState = {};
        columnNames = WatchListColumns.filter(columnInfo => columnInfo.defaultHidden !== true).map(columnInfo => columnInfo.name);
    } else {
        tableState = { ...currentState }
        const columnsProperty = currentState.columns;
        columnNames = columnsProperty.orderedFields.filter(name => columnsProperty.columnVisibilityModel[name] !== false);
    }

    if (tableState.columns === undefined) tableState.columns = {};
    if (tableState.columns.dimensions === undefined) tableState.columns.dimensions = {};

    columnNames.forEach(column => {
        const children = tableContainer.querySelectorAll(`.table-cell-${column}`);
        const maxWidth = Math.max(MinWidth, ...Array.from(children).map(child => child.clientWidth + 20));

        tableState.columns.dimensions[column] = { maxWidth: -1, MinWidth, width: maxWidth, flex: 0 };
    });

    return tableState;
}

