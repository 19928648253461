import * as React from 'react';
import { TranslationManager } from '../../Translation/Translation'; // Update the path based on your project structure
import { AppStateManager } from '../../StateManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faArrowUp } from '@fortawesome/pro-solid-svg-icons';

interface HotKeys {
  key: string,
  description: string,
}

const Hotkeys: HotKeys[] = [{
  key: 'R',
  description: TranslationManager.getTranslation().Hotkeys.ResetWorkspace,
}, {
  key: '1',
  description: TranslationManager.getTranslation().Hotkeys.MarketDepth,
}, {
  key: '2',
  description: TranslationManager.getTranslation().Hotkeys.TradeTicker,
}, {
  key: '3',
  description: TranslationManager.getTranslation().Hotkeys.WatchList,
}, {
  key: '4',
  description: TranslationManager.getTranslation().Hotkeys.Chart,
}, {
  key: '5',
  description: TranslationManager.getTranslation().Hotkeys.News,
}, {
  key: '6',
  description: TranslationManager.getTranslation().Hotkeys.PriceLadder,
}]

const TradingHotKeys: HotKeys[] = [{
  key: 'N',
  description: TranslationManager.getTranslation().Hotkeys.NewOrderModal,
}, {
  key: '8',
  description: TranslationManager.getTranslation().Hotkeys.MyOrders,
}, {
  key: '9',
  description: TranslationManager.getTranslation().Hotkeys.MyTrades,
}]

const NavigationHotkeys: HotKeys[] = [{
  key: '1',
  description: TranslationManager.getTranslation().Hotkeys.MarketDepth,
}, {
  key: '2',
  description: TranslationManager.getTranslation().Hotkeys.TradeTicker,
}, {
  key: '3',
  description: TranslationManager.getTranslation().Hotkeys.WatchList,
}]

const whatArrowToDisplay = (index: number) => {
  switch (index) {
    case 0: return faArrowUp;
    case 1: return faArrowRight;
    case 2: return faArrowDown;
    default: return faArrowUp;
  }
}

const translateNavigation = (index: number) => {
  switch (index) {
    case 0: return TranslationManager.getTranslation().Hotkeys.Up;
    case 1: return TranslationManager.getTranslation().Hotkeys.Right;
    case 2: return TranslationManager.getTranslation().Hotkeys.Down;
    default: return TranslationManager.getTranslation().Hotkeys.Up;
  }
}

const HotkeysModal: React.FC = () => {
  const HotkeysToDisplay = AppStateManager.hasAccessToPDS ? Hotkeys.concat(TradingHotKeys) : Hotkeys;

  return (
    <div className='KM_modal KM_hotkeysModal'>
      <h1>{TranslationManager.getTranslation().Buttons.Hotkeys}</h1>
      <div className='KM_modalContent'>
        <div className='KM_modalSection'>
          <div>
            <h6>
              {TranslationManager.getTranslation().Hotkeys.GlobalHeader}
            </h6>
            <ul>
              {
                HotkeysToDisplay.map((hotkey, index) => <li key={hotkey.key + index}>
                  <div className='keys-wrapper'>
                    <span className='keys'>Shift</span> + <span className='keys'>{hotkey.key}</span>
                  </div>
                  <span className='description'>{hotkey.description}</span>
                </li>)
              }
            </ul>
          </div>
          <div>
            <h6>
              {TranslationManager.getTranslation().Hotkeys.NavigateHeader}
            </h6>
            <ul>
              {
                NavigationHotkeys.map((hotkey, index) => <li key={hotkey.key + index}>
                  <div className='keys-wrapper'>
                    <span className='keys'>Shift</span> + <span className='keys'><FontAwesomeIcon icon={whatArrowToDisplay(index)} /></span> + <span className='keys'>{hotkey.key}</span>
                  </div>
                  <span className='description'>{hotkey.description} {translateNavigation(index).toLocaleLowerCase()} </span>
                </li>)
              }
            </ul>
          </div>
        </div >
      </div>
    </div>
  );
};

export default HotkeysModal;
