// Package imports:
import React, { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faInfoCircle, faExclamationCircle, faExclamationTriangle, faArrowsRotate } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Link from './Link';
// Component imports:

export type AlertType = 'success' | 'info' | 'alert' | 'error' | 'reconnecting';
interface IProps {
    type: AlertType,
    withCloseIcon?: boolean,
    headText: JSX.Element | string | undefined,
    text?: JSX.Element | string
    closeAlert?: () => void,
    moreInfoLink?: string,
    actions?: JSX.Element,
}

// Helper function to enforce all keys
function createIconMap<T extends Record<AlertType, any>>(obj: T): T {
return obj;
}

// Define icon
export const ALERTICONS = createIconMap({
'success': faCheckCircle,
'info': faInfoCircle,
'alert': faExclamationCircle,
'error': faExclamationTriangle,
'reconnecting': faArrowsRotate
});

const Alert: React.FC<IProps> = ({
    type,
    withCloseIcon,
    headText,
    text,
    closeAlert,
    moreInfoLink,
    actions
}) => {
    const [show, setShow] = useState(true);
    return (
        <div className={cx('KCL_alert', type, text ? 'lg' : 'sm', { 'dismissed': withCloseIcon && closeAlert === undefined && !show })}>
            <span className="alert-header">
                <div className='icon-and-header'>
                    <FontAwesomeIcon icon={ALERTICONS[type]} className={`icon ${type === 'reconnecting' ? 'spin' : ''}`} />
                    <p className="head-text paragraph-small--bold">{headText}</p>
                </div>
                {withCloseIcon &&
                    <div
                        onClick={closeAlert ?? ((e) => {e.preventDefault(); setShow(false)})}
                        className="close-button"
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                }
            </span>
            {text &&
                <div className="main-text">
                    <div className='paragraph-small'>
                        {text}
                    </div>
                    {moreInfoLink &&
                        <Link
                            url={moreInfoLink}
                            linkSize="16"
                            targetBlank
                        >
                            Nánari upplýsingar
                        </Link>
                    }
                </div>
            }
            {actions &&
            actions}
        </div>
    );
}

export default Alert;