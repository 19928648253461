/*
import { MarketDepthType, MessageType, OrderbookState, SubscriptionCommands } from "../../../Types/Websocket";
import { mapExpressionToValue } from "../../../Utils/Common";
import { WSManager } from "../WebSoketManager";
import { MessageTypeHandler } from "./AbstractHandler";
import { BehaviorSubject, Subscription } from "rxjs";



type LevelDescriptionMessage = {
    level: number;
    orders: number;
    price: number;
    symbol: string;
    type: "entry";
    volume: number;
}

type LevelDescription = {
    orders: number;
    price: number;
    volume: number;
}

type MarketDepthInfo = {
    orderbook_state: OrderbookState;
    asks: Record<number, LevelDescription>;
    bids: Record<number, LevelDescription>;
}

export class MarketDepthHandler extends MessageTypeHandler<MarketDepthMessage> {
    symbolHandlerDetails: Record<string, {
        marketDepthInfo: MarketDepthInfo;
        behaviorSubject: BehaviorSubject<MarketDepthInfo>
        subscriptionCount: number;
    }>;


    constructor() {
        super();
        this.symbolHandlerDetails = {};
    }

    handleMessage(message: MarketDepthMessage) {
        const symbol = message.symbol;
        if(message.initial)this.symbolHandlerDetails[symbol].marketDepthInfo = {orderbook_state: 'closed', asks: {}, bids: {}}
        const { marketDepthInfo, behaviorSubject } = this.symbolHandlerDetails[symbol];

        // Update orderbook_state
        if('orderbook_state' in message)marketDepthInfo['orderbook_state'] = message['orderbook_state'];


        // Update ask and bid sides
        ['asks', 'bids'].forEach(side => { 
            if(side in message)message[side].forEach(levelDescription => {
                const {level, orders, price, symbol, type, volume} = levelDescription;
                if(volume === 0 || orders === 0)delete marketDepthInfo[side][level];
                else marketDepthInfo[side][level] = {orders, price, volume};
            });
        });


        behaviorSubject.next(marketDepthInfo);
    }

    subscribe(symbol: string, callback: (mdi: MarketDepthInfo) => void): Subscription {
        if(!(symbol in this.symbolHandlerDetails))this.symbolHandlerDetails[symbol] = {
            subscriptionCount: 0,
            behaviorSubject: new BehaviorSubject({orderbook_state: 'closed', asks: {}, bids: {}}),
            marketDepthInfo: {orderbook_state: 'closed', asks: {}, bids: {}}
        }

        this.symbolHandlerDetails[symbol].subscriptionCount += 1;
        // if subscriptionCount for symbol went from 0 -> 1
        if(this.symbolHandlerDetails[symbol].subscriptionCount === 1)
            WSManager.manageSymbolSubscription(symbol, SubscriptionCommands.Subscribe, MessageType.third_level);


        const subscription = this.symbolHandlerDetails[symbol].behaviorSubject.subscribe(callback);
        const tearDownLogic = () => {
            this.symbolHandlerDetails[symbol].subscriptionCount -= 1;
            // if subscriptionCount for symbol went from 1 -> 0
            if(this.symbolHandlerDetails[symbol].subscriptionCount === 0)
                WSManager.manageSymbolSubscription(symbol, SubscriptionCommands.Unsubscribe, MessageType.third_level);
        }; 
        subscription.add(tearDownLogic);
        return subscription;
    }
}

*/

import {
  MessageType,
  OrderbookState,
  SymbolMessageType,
} from "../../../Types/Websocket";
import { SymbolMessageHandler } from "./AbstractHandler";
// Incoming message
type MBLMessage = {
  initial: boolean;
  orderbook_state: OrderbookState;
  symbol: string;
  asks: LevelDescriptionMessage[];
  bids: LevelDescriptionMessage[];
};
type LevelDescriptionMessage = {
  level: number;
  orders: number;
  price: number;
  symbol: string;
  type: "entry";
  volume: number;
};

// Stored symbol info
export type MBLInfo = {
  asks: Record<number, LevelDescription>;
  bids: Record<number, LevelDescription>;
};
type LevelDescription = {
  orders: number;
  price: number;
  volume: number;
};

export class MBLHandler extends SymbolMessageHandler<MBLMessage, MBLInfo> {
  messageType: SymbolMessageType = MessageType.market_by_level;

  initialValue(symbol: string): MBLInfo {
    return { asks: {}, bids: {} };
  }

  messageSymbol(message: MBLMessage): string {
    return message.symbol;
  }

  handleSymbolsMessage(symbol: string, message: MBLMessage): void {
    const { symbolInfo } = this.symbolHandlerDetails[symbol];

    // Update orderbook_state
    if ("orderbook_state" in message)
      symbolInfo["orderbook_state"] = message["orderbook_state"];

    // Update ask and bid sides
    ["asks", "bids"].forEach((side) => {
      if (side in message)
        message[side].forEach((levelDescription) => {
          const { level, orders, price, symbol, type, volume } =
            levelDescription;
          if (volume === 0 || orders === 0) delete symbolInfo[side][level];
          else symbolInfo[side][level] = { orders, price, volume };
        });
    });

    this.pushUpdate(symbol);
  }
}
