import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import * as React from "react";
import { GLWindowManager } from "../Windows/GLWindowManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faAngleDown,
  faArrowsRotate,
  faCirclePlus,
  faCloudPlus,
  faFileArrowUp,
  faFilePlus,
  faFileSpreadsheet,
  faFolderOpen,
  faGrid2Plus,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import cx from "classnames";

import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Dialog from "@mui/material/Dialog";
import { Popover } from "@mui/material";

import { TranslationManager } from "../../Translation/Translation";
import FileUpload, { parseContentItems } from "./FileUpload";
import CustomButton from "../UI-Elements/CustomButton";
import { ComponentNameList } from "../../Types/Windows";
import Input from "../UI-Elements/Input";

export const DialogStyleContainer = {
  padding: "40px",
  borderRadius: "inherit",
};
export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string | null) => void;
}

function LoadWorkspaceDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;

  return (
    <Dialog onClose={() => onClose(null)} open={open}>
      <div className="KM_modal KM_loadModal" style={DialogStyleContainer}>
        <div className="jc-sb">
          <h1>{TranslationManager.getTranslation().Titles.LoadWorkspace}</h1>
          <FontAwesomeIcon
            className="modalCloseButton"
            onClick={() => onClose(null)}
            icon={faXmark}
          />
        </div>
        <List
          style={{
            maxHeight: "300px",
          }}
          sx={{ pt: 0 }}
        >
          {GLWindowManager.getWorkspacenames().map((name, index) => (
            <div
              className="workspace_item"
              onClick={() => {
                GLWindowManager.loadWorkspace(name);
                onClose(name);
              }}
              key={index}
            >
              <FontAwesomeIcon icon={faFileSpreadsheet} className="fa-icon" />
              <span className="workspace_name">{name}</span>
            </div>
          ))}
        </List>
      </div>
    </Dialog>
  );
}

function SaveAsDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const [value, setValue] = React.useState<null | string>(null);
  function validSaveFileName(name: string): boolean {
    return (
      Boolean(name.toLocaleLowerCase().match(/^[\wþæöðíóáéúý]+$/)) &&
      name.length < 20
    );
  }

  return (
    <Dialog onClose={() => onClose(null)} open={open} maxWidth={"xl"}>
      <div className="KM_modal KM_saveModal" style={DialogStyleContainer}>
        <div className="jc-sb">
          <h1>{TranslationManager.getTranslation().Titles.SaveWorkspace}</h1>
          <FontAwesomeIcon
            className="modalCloseButton"
            onClick={() => onClose(null)}
            icon={faXmark}
          />
        </div>
        <div className="inputContainer">
          <Input
            placeholder={
              TranslationManager.getTranslation().Save_Modal.InputPlaceHolder
            }
            value={value as string}
            onChange={(e) => setValue(e.target.value)}
            required
            fullWidth
            autoFocus
          />
          <CustomButton
            text={TranslationManager.getTranslation().Buttons.Save}
            handleClose={() => {
              if (validSaveFileName(value || "")) {
                onClose(value);
              } else
                alert(
                  "Name can not include space, tabs or any of these special characters ~!@#$%^&*()_+-={}|[]:\";'<>?,./ "
                );
            }}
            className={"KM_modalButton"}
          />
        </div>
      </div>
    </Dialog>
  );
}

function ImportWorkspaceDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const handleFileUpload = (
    file: string,
    content: string | ArrayBuffer | null | undefined
  ) => {
    // Handle the uploaded file here, e.g., send it to a server
    const parsed = parseContentItems(content);
    if (parsed !== null) {
      GLWindowManager.saveImportedWS(file, parsed);
      GLWindowManager.loadWorkspace(file);
      onClose(null);
    } else {
      alert("Something went wrong");
    }
  };

  return (
    <Dialog onClose={() => onClose(null)} open={open} maxWidth={"xl"}>
      <div className="KM_modal " style={DialogStyleContainer}>
        <div className="jc-sb">
          <h1>{TranslationManager.getTranslation().Titles.ImportWorkspace}</h1>
          <FontAwesomeIcon
            className="modalCloseButton"
            onClick={() => onClose(null)}
            icon={faXmark}
          />
        </div>
        <FileUpload onFileUpload={handleFileUpload} />
      </div>
    </Dialog>
  );
}

function ExportWorkspaceDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const [fileName, setFileName] = React.useState<string>("");

  function validSaveFileName(name: string): boolean {
    return (
      Boolean(name.toLocaleLowerCase().match(/^[\wþæöðíóáéúý]+$/)) &&
      name.length < 20
    );
  }

  function downloadJsonFile() {
    const jsonString = JSON.stringify(GLWindowManager.getContent());
    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a hidden <a> element to trigger the download
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${fileName}.json`; // Set the file name for the download

    // Trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the URL and removing the <a> element
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  return (
    <Dialog onClose={() => onClose(null)} open={open} maxWidth={"xl"}>
      <div className="KM_exportModal KM_modal" style={DialogStyleContainer}>
        <div className="jc-sb">
          <h1>{TranslationManager.getTranslation().Titles.ExportWorkspace}</h1>
          <FontAwesomeIcon
            className="modalCloseButton"
            onClick={() => onClose(null)}
            icon={faXmark}
          />
        </div>
        <div className="inputContainer">
          <Input
            placeholder={
              TranslationManager.getTranslation().Save_Modal.InputPlaceHolder
            }
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            required
            fullWidth
            autoFocus
          />
          <CustomButton
            text={TranslationManager.getTranslation().Buttons.Save}
            handleClose={() => {
              if (validSaveFileName(fileName || "")) {
                downloadJsonFile();
                onClose(null);
              } else
                alert(
                  "Name can not include space, tabs or any of these special characters ~!@#$%^&*()_+-={}|[]:\";'<>?,./ "
                );
            }}
            className={"KM_modalButton"}
          />
        </div>
      </div>
    </Dialog>
  );
}

export function WorkspaceDropDown() {
  const [loadDialogOpen, setLoadDialogOpen] = React.useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);
  const [UploadWorkspaceOpen, setUploadWorkspaceOpen] = React.useState(false);
  const [ExportWorkspaceOpen, setExportWorkspaceOpen] = React.useState(false);

  interface menuItem {
    text: string;
    onClick: () => void;
    icon?: IconDefinition;
  }
  const WorkspaceItems: menuItem[] = [
    {
      text: TranslationManager.getTranslation().Header.Menu.WorkspaceMenu
        .ResetWorkspace,
      onClick: GLWindowManager.reset,
      icon: faArrowsRotate,
    },
    {
      text: TranslationManager.getTranslation().Header.Menu.WorkspaceMenu
        .OpenWorkspace,
      onClick: () => setUploadWorkspaceOpen(true),
      icon: faFolderOpen,
    },
    {
      text: TranslationManager.getTranslation().Header.Menu.WorkspaceMenu
        .LoadWorkspace,
      onClick: () => setLoadDialogOpen(true),
      icon: faFileArrowUp,
    },
    {
      text: TranslationManager.getTranslation().Header.Menu.WorkspaceMenu
        .SaveWorkspace,
      onClick: () => setSaveDialogOpen(true),
      icon: faCloudPlus,
    },
    {
      text: TranslationManager.getTranslation().Header.Menu.WorkspaceMenu
        .ExportWorkspace,
      onClick: () => setExportWorkspaceOpen(true),
      icon: faFilePlus,
    },
  ];

  function EdgeCaseFixComponentItem({ componentName }) {
    const ref = React.createRef<any>();

    React.useEffect(() => {
      ref.current.appendChild(
        GLWindowManager.draggableAddElements[componentName]
      );
    });

    return (
      <div className="menu-item" ref={ref}>
        <FontAwesomeIcon className="fa-icon" icon={faCirclePlus} />
      </div>
    );
  }

  return (
    <>
      <PopupState variant="popover">
        {(popupState) => (
          <React.Fragment>
            <Button
              className="noHoverButton"
              disableRipple
              size="small"
              variant="text"
              {...bindTrigger(popupState)}
            >
              <div
                className={cx("headerButton", { active: popupState.isOpen })}
              >
                <FontAwesomeIcon
                  className="fa-icon"
                  style={{ fontSize: "14px", marginRight: "6px" }}
                  icon={faGrid2Plus}
                  flip={"vertical"}
                />
                {TranslationManager.getTranslation().Buttons.Workspace}
                <FontAwesomeIcon
                  className={cx("fa-icon rotate", {
                    "rotate-180": popupState.isOpen,
                  })}
                  style={{ marginLeft: "8px" }}
                  icon={faAngleDown}
                />
              </div>
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="dwMenu">
                {/* Dashboard */}
                <div className="dashboard">
                  <span className="small_header">
                    {
                      TranslationManager.getTranslation().Header.Menu
                        .DashboardMenu.Header
                    }
                  </span>
                  {ComponentNameList.map((componentName) => (
                    <div
                      className="wrapper"
                      onClick={() => {
                        GLWindowManager.addComponentToRoot(componentName);
                        popupState.close();
                      }}
                      key={componentName}
                    >
                      <EdgeCaseFixComponentItem componentName={componentName} />
                    </div>
                  ))}
                </div>
                {/* empty vertical rule */}
                <div className="dwMenu_vr"></div>
                {/* Workspace */}
                <div className="workspace">
                  <span className="small_header">
                    {
                      TranslationManager.getTranslation().Header.Menu
                        .WorkspaceMenu.Header
                    }
                  </span>
                  {WorkspaceItems.map((item) => (
                    <div
                      className="wrapper"
                      onClick={() => {
                        item.onClick();
                        popupState.close();
                      }}
                      key={item.text}
                    >
                      <div className="menu-item">
                        {item.icon && (
                          <span
                            style={{
                              width: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              icon={item.icon}
                            />
                          </span>
                        )}
                        <div>{item.text}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Popover>
          </React.Fragment>
        )}
      </PopupState>
      <LoadWorkspaceDialog
        open={UploadWorkspaceOpen}
        onClose={() => {
          setUploadWorkspaceOpen(false);
        }}
      />
      <SaveAsDialog
        open={saveDialogOpen}
        onClose={(value) => {
          const name = value;
          if (name !== null) GLWindowManager.saveAs(name);
          setSaveDialogOpen(false);
        }}
      />
      <ImportWorkspaceDialog
        open={loadDialogOpen}
        onClose={(value) => {
          const name = value;
          if (name !== null) GLWindowManager.loadWorkspace(name);
          setLoadDialogOpen(false);
        }}
      />
      <ExportWorkspaceDialog
        open={ExportWorkspaceOpen}
        onClose={() => {
          setExportWorkspaceOpen(false);
        }}
      />
    </>
  );
}
