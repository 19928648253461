import React, { Component, createRef } from 'react';
import { GLWindowManager } from '../Windows/GLWindowManager';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { AppStateManager } from '../../StateManager';

import './Main.scss';
import { Environment } from '../../Config/Environment';
import { SymbolProperties } from './SymbolProperties';
import { Config } from '../../Config/Config';
import { DefaultModal } from './DefaultModal';
import { ToastContainer } from 'react-toastify';
import hotkeys from 'hotkeys-js';
import { hotkeyActions, TradingHotkeys, TradingHotkeysTypes, WSHotkeys, WSHotkeysTypes } from '../../Types/Hotkeys';
import { GoogleAnalytics } from '../..';


export const dialogContainerRef = React.createRef<SymbolProperties>();
export function openPropertiesOfSymbol(symbol: string): void {
    dialogContainerRef.current?.show(symbol);
}

export const defaultDialogContainerRef = React.createRef<DefaultModal>();
export function openDefaultModal(symbol: JSX.Element): void {
    defaultDialogContainerRef.current?.show(symbol);
}
export function hideDefaultModal(): void {
    defaultDialogContainerRef.current?.hide();
}

export const mainDivRef = React.createRef<HTMLDivElement>();

export class Main extends Component {
    GLContainerRef: React.RefObject<HTMLDivElement>;
    state: { initialized: boolean };
    navigate;
    constructor(props) {
        super(props);
        this.state = { initialized: false };
        this.GLContainerRef = createRef<HTMLDivElement>();
    }
    async componentDidMount() {
        //send page view to google analytics
        GoogleAnalytics.page();
        if (!AppStateManager.logedIn.value) {
            const token = localStorage.getItem(Config.API_TOKEN_COOKIE);
            if (token === null) return AppStateManager.logout(); // If no token is stored go to login
            else try {
                await AppStateManager.login(token);
            } catch (error) {
                if (Environment === 'development') {
                    console.log('Token expired or other errors, redirecting to login');
                    console.error(error);
                }
                return AppStateManager.logout(); // Token expired or other errors
            }
        }

        const GLWMContainer = this.GLContainerRef.current as HTMLDivElement;
        GLWindowManager.init(GLWMContainer);
        this.setState({ initialized: true });

        const availableHotkeys: (WSHotkeysTypes | TradingHotkeysTypes)[] = [...(WSHotkeys as WSHotkeysTypes[])];
        //if user has access to PDS, add PDS hotkeys
        if (AppStateManager.hasAccessToPDS) {
            availableHotkeys.push(...(TradingHotkeys) as TradingHotkeysTypes[])
        }
        hotkeys(availableHotkeys.toString(), function (event, handler) {
            const action = hotkeyActions[handler.key as WSHotkeysTypes | TradingHotkeysTypes];
            if (action) action(event);
        });
    }

    componentWillUnmount(): void {
        if (this.state.initialized) {
            GLWindowManager.destroy();
        }
    }

    render(): React.ReactNode {
        return (
            <div className='main fill' ref={mainDivRef}>
                {this.state.initialized && <Header active={true} />}
                {this.state.initialized && <div id="warning-banner" className="center-container"></div>}
                <div ref={this.GLContainerRef} id='GLWMContainer' style={{ gridArea: 'windows' }}></div>
                {this.state.initialized && <Footer />}
                <SymbolProperties ref={dialogContainerRef} />
                <DefaultModal ref={defaultDialogContainerRef} />
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    stacked
                />
            </div>
        );
    }
}