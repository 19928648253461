/**
 * Private Data Streamer API
 * Private Data Streamer Swagger API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: dagur@kodi.is
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The time-in-force of an order.
 * Specifies how long the order will remain active before being executed or expired.
 * Not applicable for all orders,
 * as e.g. market orders are always immediate_or_cancel or fill_or_kill.
 */
export type TimeInForce = 'day' | 'good_till_cancel' | 'immediate_or_cancel' | 'fill_or_kill' | 'good_till_date';

export const TimeInForce = {
    day: 'day' as TimeInForce,
    good_till_cancel: 'good_till_cancel' as TimeInForce,
    immediate_or_cancel: 'immediate_or_cancel' as TimeInForce,
    fill_or_kill: 'fill_or_kill' as TimeInForce,
    good_till_date: 'good_till_date' as TimeInForce
};
