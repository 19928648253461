import React from 'react';
import { ListInfo } from "../../../Types/LMDTypes";
import { SymbolListWindowComponent } from "../AbstractWindow";
import { WatchListTable } from "./WatchListTable";
import cx from 'classnames';

import { Autocomplete, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { LMDInterface } from '../../../KodiInterface/LMD';
import { GridEventListener } from '@mui/x-data-grid-pro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

type TableState = any;
type SaveState = {
    tableState: TableState;
};

type RunState = {
    tableParentRef: React.RefObject<HTMLDivElement>;
    lists: ListInfo[];
}
export class WatchList extends SymbolListWindowComponent<RunState, SaveState> {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            tableParentRef: React.createRef<HTMLDivElement>(),
            lists: []
        };
    }

    componentDidMount(): void {
        LMDInterface.getAllLists().then(lists => { this.setState({ lists }) });
    }

    componentDidUpdate(prevProps, prevState, snapshot?: any): void {
        // Save state on each change
        this.saveState({ linked: this.state.linked, tableState: this.state.tableState, list: this.state.list });
    }

    render() {
        const onRowClick: GridEventListener<'rowClick'> = (params) => {
            const row = params.row;
            const symbol = row.symbol;
            this.selectedSymbol(symbol);
        };


        return (
            <div className='KM_watch_list window' ref={this.state.tableParentRef}>
                <div className='fill' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', maxWidth: '260px', width: '100%' }}>
                        <ListAutocomplete
                            lists={this.state.lists}
                            value={this.state.list}
                            onChange={(listInfo) => this.setState({ list: listInfo })}
                        />
                    </div>
                    <div>{this.linkedButton()}</div>
                </div>

                <WatchListTable
                    list={this.state.list}

                    rowsSelectable={this.state.linked}
                    onRowClick={onRowClick}

                    tableState={this.state.tableState}
                    saveState={(state) => this.setState({ tableState: state })}
                />
            </div>
        );
    }
}


function ListAutocomplete({ lists, value, onChange }: { lists: ListInfo[], value: ListInfo | undefined, onChange: (list: ListInfo) => void }) {
    function listInfoToItem(listInfo: ListInfo): ListInfo & { label: string } {
        return { ...listInfo, label: listInfo.name || '' }
    }
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <Autocomplete
            autoHighlight
            popupIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '10px' }} />}
            options={lists.map(listInfoToItem)}
            groupBy={(option) => option.country as string}
            sx={{
                borderRadius: '6px',
                width: '100%', padding: 0,
                '& input': {
                    width: 200,
                    height: 20
                }
            }}
            renderInput={(params) => <TextField {...params}
                size='small'
                className={cx('autocomplete-custom', { 'isOpen': isOpen })}
                sx={{
                    padding: '0px',
                }}
                inputProps={{
                    ...params.inputProps,
                }}
            />
            }
            renderGroup={(params) => {
                const GroupItems = styled('ul')({ padding: 0 });
                return <li key={params.key} style={{ paddingTop: 6, paddingBottom: 4 }}>
                    <div style={{
                        paddingLeft: 8, paddingRight: 8, paddingTop: 0, paddingBottom: 4,
                        background: 'var(--white)',
                        textTransform: 'uppercase',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        color: 'var(--dark-400)',
                    }}>{params.group}</div>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props} sx={{
                    color: 'var(--dark-900, #232530)',
                    fontSize: '11px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }} >
                    <div style={{
                        maxWidth: '85%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                    }}>{option.name}
                    </div>
                    <div style={{
                        paddingLeft: 0,

                    }}>&nbsp;({option.count})</div>
                </Box >
            )}
            onOpen={() => {
                setIsOpen(true);
            }}
            onClose={() => {
                setIsOpen(false);
            }}
            disableClearable
            value={value}
            size='small'
            isOptionEqualToValue={(a, b) => JSON.stringify(a) === JSON.stringify(b)}
            onChange={(event: any, newValue: any) => onChange(newValue as ListInfo)}
        />);
}