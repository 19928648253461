import { Component } from 'react';

import Dialog from '@mui/material/Dialog';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { LMDInterface } from '../../KodiInterface/LMD';
import { MoreTradeableInfo } from '../../Types/LMDTypes';

import { MUITable } from '../Tables/MUIProTable';
import { mapExpressionToValue } from '../../Utils/Common';
import DialogActions from '@mui/material/DialogActions';
import { ColumnInfo } from '../../Types/MUITable';
import { TranslationManager } from '../../Translation/Translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

type InfoGroup = "general" | "share-info" | "market-info" | "tick-sizes" | "trading-scheme" | "supplementary";
//["general", "share-info", "market-info", "tick-sizes", "trading-scheme", "supplementary"]


type State = {
    show: boolean;
    symbol: string;
    alignment: InfoGroup;

    tradableInfo: MoreTradeableInfo | {};

    rows: (Record<string, any> & { id: string })[];
    columns: ColumnInfo[];
}
export class SymbolProperties extends Component<any, State> {
    constructor(props) {
        super(props);
        this.state = this.defaultState();
    }

    private defaultState(): State {
        return {
            show: false,
            symbol: '',
            alignment: 'general',

            tradableInfo: {},
            rows: [],
            columns: []
        }
    }


    show(symbol: string) {
        this.setState(this.defaultState());
        this.setState({ symbol });
        LMDInterface.getMoreTradeableInfo(symbol).then(info => this.setState({ tradableInfo: info }));
        this.setRows(symbol, this.state.alignment);
        this.setState({ show: true });
    }

    hide() {
        this.setState({ show: false });
    }

    handleAlignment(event: React.MouseEvent<HTMLElement>, newAlignment: InfoGroup | null) {
        if (newAlignment !== null) {
            const alignment: InfoGroup = newAlignment;
            this.setState({ alignment });
            this.setRows(this.state.symbol, alignment);
        }
    }

    async setRows(symbol: string, group: InfoGroup) {
        function objectToArray(object) {
            return Object.keys(object).map(key => { return { property: key, value: object[key], id: key } });
        }
        const { rows, columns }: { rows: any, columns: ColumnInfo[] } = await mapExpressionToValue({
            'general': async () => {
                const columns: ColumnInfo[] = [{ name: 'property' }, { name: 'value' }];

                const rows = objectToArray(await LMDInterface.getMoreTradeableInfo(symbol));

                return { rows, columns };
            },
            'market-info': () => null,
            'share-info': () => null,
            'supplementary': async () => {
                const columns = [{ name: 'property' }, { name: 'value' }];

                const rows = objectToArray(await LMDInterface.getSupplementaryData(symbol));

                return { rows, columns };
            },
            'tick-sizes': async () => {
                const columns: ColumnInfo[] = [
                    { dataType: 'number', width: 133, name: 'from' },
                    { dataType: 'number', width: 133, name: 'to' },
                    { dataType: 'number', width: 134, name: 'tickSize' }
                ];

                const id = this.state.tradableInfo['TickSizeTableId'];
                const tickSizes = await LMDInterface.getTickSizes(id);
                const rows = tickSizes.map(tick => { return { from: tick.PriceFrom, to: tick.PriceTo, tickSize: tick.TickSize, id: tick.PriceFrom } });

                return { rows, columns };
            },
            'trading-scheme': async () => {
                const columns = [{ name: 'time' }, { name: 'marketState' }];

                const marketSymbol = this.state.tradableInfo['MarketSymbol'];
                const marketStatusInfos = await LMDInterface.getTradingScheme(marketSymbol);
                const rows = marketStatusInfos.map(statusInfo => { return { time: statusInfo.StartTime, marketState: statusInfo.MarketStateCode, id: statusInfo.MarketStateCode } })

                return { rows, columns };
            },
        }, group)();

        columns.forEach(column => {
            column.alignment = 'left';
            if (!column.width) column.width = 200;
        });
        this.setState({ rows, columns });
    }

    render() {
        const generalInfo: { title: string, value: string }[] = [{
            title: TranslationManager.getTranslation().OrderBook_Modal.Name,
            value: 'Name',
        }, {
            title: TranslationManager.getTranslation().OrderBook_Modal.Short_Name,
            value: 'AbbreviatedName',
        }, {
            title: TranslationManager.getTranslation().OrderBook_Modal.ISIN,
            value: 'Isin',
        }, {
            title: TranslationManager.getTranslation().OrderBook_Modal.Currency,
            value: 'TradingCurrency',
        }, {
            title: TranslationManager.getTranslation().OrderBook_Modal.Country,
            value: 'Country',
        },
        ]

        const toggleButtons = {
            'general': TranslationManager.getTranslation().OrderBook_Modal.ToggleButtons.General,
            'share-info': TranslationManager.getTranslation().OrderBook_Modal.ToggleButtons.Share_Info,
            'market-info': TranslationManager.getTranslation().OrderBook_Modal.ToggleButtons.Market_Info,
            'tick-sizes': TranslationManager.getTranslation().OrderBook_Modal.ToggleButtons.Tick_Sizes,
            'trading-scheme': TranslationManager.getTranslation().OrderBook_Modal.ToggleButtons.Trading_Scheme,
            'supplementary': TranslationManager.getTranslation().OrderBook_Modal.ToggleButtons.Supplementary,
        }
        return <Dialog
            open={this.state.show}
            onClose={this.hide.bind(this)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

            maxWidth={'xl'}
        >
            <div style={{ cursor: 'pointer', position: 'absolute', right: 40, top: 40 }} onClick={this.hide.bind(this)}>
                <DialogActions>
                    <FontAwesomeIcon icon={faTimes} style={{ fontSize: '16px' }} />
                    {/* <Button color='secondary' onClick={this.hide.bind(this)}><CloseIcon /></Button> */}
                </DialogActions>
            </div>

            <div className='KM_OrderBook'>
                <div style={{ width: '100%' }}>
                    <h3 className='OrderBook_header'>{TranslationManager.getTranslation().OrderBook_Modal.Orderbook_title}: {this.state.symbol}</h3>
                </div>
                <div className='general-info-container'>
                    {
                        generalInfo.map((info, index) => {
                            return <div className='general-info-row' key={index}>
                                <span className='general-info-header'>{info.title}</span>
                                <span className='general-info-value'>{this.state.tradableInfo[info.value]}</span>
                            </div>
                        })
                    }
                    {/* SPECIAL CASE FOR SECURITY TYPE */}
                    <div className='general-info-row'>
                        <span className='general-info-header'>{TranslationManager.getTranslation().OrderBook_Modal.Security_Type}</span>
                        <span className='general-info-value'>{
                            this.state.tradableInfo["SecurityType"] === "share"
                                ? TranslationManager.getTranslation().OrderBook_Modal.SecurityType_share
                                : this.state.tradableInfo["SecurityType"] === "bond"
                                    ? TranslationManager.getTranslation().OrderBook_Modal.SecurityType_bond
                                    : this.state.tradableInfo["SecurityType"]
                        }
                        </span>
                    </div>
                </div>
                <div style={{ marginBottom: '5px' }} className='center-container'>
                    <ToggleButtonGroup
                        value={this.state.alignment}
                        exclusive
                        onChange={this.handleAlignment.bind(this)}
                        size="small"
                    >
                        {
                            Object.entries(toggleButtons).map(([key, value]) => {
                                return <ToggleButton key={key} disabled={key === "share-info" || key === "market-info"} value={key}>
                                    {value}
                                </ToggleButton>
                            })
                        }
                    </ToggleButtonGroup>
                </div>
                <div style={{ height: '210px' }}>
                    <MUITable
                        styles={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                        rows={this.state.rows}
                        columns={this.state.columns}
                        cell={(column, row) => <div>{row[column]}</div>}

                        tableState={undefined}
                        saveState={(state) => null}
                        disableColumnMenu={true}
                    />
                </div>
            </div>
        </Dialog>
    }
}