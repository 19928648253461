import { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import { MoreTradeableInfo } from '../../Types/LMDTypes';
import { ColumnInfo } from '../../Types/MUITable';

type State = {
    show: boolean;
    component: JSX.Element;

    tradableInfo: MoreTradeableInfo | {};

    rows: (Record<string, any> & { id: string })[];
    columns: ColumnInfo[];
}

export class DefaultModal extends Component<any, State> {
    constructor(props) {
        super(props);
        this.state = this.defaultState();
    }

    private defaultState(): State {
        return {
            show: false,
            component: <></>,

            tradableInfo: {},
            rows: [],
            columns: []
        }
    }


    show(component: JSX.Element) {
        this.setState(this.defaultState());
        this.setState({ component });
        this.setState({ show: true });
    }

    hide() {
        this.setState({ show: false });
    }

    render() {

        return <Dialog
            open={this.state.show}
            onClose={this.hide.bind(this)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'xl'}
        >
            {this.state.component}
        </Dialog>
    }
}