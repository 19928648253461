// Package imports:
import React, { memo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import cx from 'classnames';
import Link from '../../UI-Elements/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
// import Link from '../../../ui-elements/Link/Link';

interface IProps {
    title: string | null,
    description: string,
    date: string | null | JSX.Element,
    source: string | null | undefined,
    fullSource: string | null | undefined,
    link: string | null
    isNew: boolean, // the yellow notifier next to the title
    isHighlighted: boolean, // The highlighted color.
    isSeen: boolean,
    onOpen(): void
}

const NewsItem: React.FC<IProps> = ({
    title,
    description,
    date,
    source,
    link,
    isNew,
    isHighlighted,
    isSeen,
    onOpen
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const expandNews = () => {
        setIsOpen(!isOpen);
        onOpen();
    }

    return (
        <div
            className={cx('frettavakt-item', {
                'is-open': isOpen,
                'is-seen': isSeen,
                'is-new': isNew,
                'is-highlighted': isHighlighted
            })}
        >
            <div
                className="news-item__head"
                onClick={expandNews}
            >
                <div
                    className="news-item__trigger"
                >
                    <span className="news-item__title">{title}</span>
                    <div className='notify-wrapper'>
                        {!isSeen && isNew && (
                            <div className="news-item__notify"></div>
                        )}
                    </div>
                </div>


                <div className="news-item__meta">
                    <span className='news-item__source'>{source}</span>
                    <span className="news-item__time"><strong>{date}</strong></span>
                </div>
            </div>

            <AnimateHeight
                className="news-item__content"
                duration={300}
                height={isOpen ? 'auto' : 0}
            >
                <div className="news-item__body">
                    <div className="news-item__entry" dangerouslySetInnerHTML={{ __html: description }} />

                    <div className="news-item__actions">
                        <Link linkSize='12' url={link ?? '#'} target='_blank'><FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '11px' }} />Skoða frétt</Link>
                        {/* <span className="news-item__source">{fullSource}</span> */}
                    </div>
                </div>
            </AnimateHeight>
        </div>
    )
}

export default memo(NewsItem);